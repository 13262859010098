#menu {
	position: fixed;
	top: 0;
	right: 0;
	background-color: #00000080;
	width: 100%;
	height: 100dvh;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
	pointer-events: none;
	z-index: 400;
}

#menu.visible {
	opacity: 1;
	pointer-events: auto;
}

#menu .container {
	position: fixed;
	top: 0;
	right: -350px;
	background-color: var(--c1);
	padding: 15px;
	width: 100%;
	height: 100dvh;
	overflow: auto;
	transition: right 0.2s ease-in-out;
	display: flex;
	flex-direction: column;
}

#menu.visible .container {
	right: 0;
}

#menu .header {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 20px;
	padding: 10px 15px;
}

#menu .header button {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--c2c);
	color: var(--c1);
	border: 0;
	border-radius: 5px;
}
#menu .header select,
#menu .header a {
	padding: 10px 20px;
}
#menu .header select {
	min-width: 150px;
	color: #fff;
	background-image: url(data:image/svg+xml;utf8,<svg fill=%27white%27 height=%2724%27 viewBox=%270 0 24 24%27 width=%2724%27 xmlns=%27http://www.w3.org/2000/svg%27><path d=%27M7 10l5 5 5-5z%27/><path d=%27M0 0h24v24H0z%27 fill=%27none%27/></svg>);
}
#menu .header select option {
	color: #000;
}
#menu .header a {
	border: 1px solid var(--c1c);
	background-color: unset;
	color: var(--c1c);
}
#menu .content {
	padding: 10px 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex-grow: 1;
	overflow: auto;
	max-width: 500px;
}
#menu .content a,
#menu .content button {
	background-color: unset;
	color: var(--c1c);
	text-align: left;
	font-size: 16px;
	border: 0px;
}
#menu .content a,
#menu .content .button,
#menu .content .submenu-button {
	padding: 10px 0px;
	position: relative;
}
#menu .content a:after,
#menu .content .button:after,
#menu .content .submenu-button:after {
	content: "";
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 1px;
	bottom: 0px;
	left: 0;
	background-color: #fff;
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}
#menu .content a:hover:after,
#menu .content .button:hover:after,
#menu .content .submenu-button:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

#menu .button {
	text-align: left;
}
#menu .button-icon svg {
	color: var(--c1c) !important;
}
#menu .content .submenu-button {
	justify-content: left;
}

#menu .content .submenu {
	background-color: unset;
	margin-left: 50px;
	padding: 0 30px;
	border-left: 1px solid #fff;
}
#menu .submenu-container.accordion .submenu {
	padding: 0 30px;
}
#menu .content .submenu a {
	padding: 10px 0;
}

#menu .content > * span.small {
	display: block;
	font-size: 12px;
}
#menu .account-button .small {
	margin-left: 10px;
}

#menu .footer {
	padding-top: 25px;
	margin-top: 15px;
	border-top: 1px solid #fff;
}
#menu .footer .info {
	font-size: 12px;
	color: #fff;
	display: flex;
	gap: 5px 15px;
	flex-wrap: wrap;
}
#menu .footer .info a {
	text-decoration: none !important;
}

@media (min-width: 768px) {
	#menu .container {
		padding: 20px;
	}
	#menu .header {
		flex-direction: row;
		gap: 20px;
	}

	#menu .content a,
	#menu .content .button,
	#menu .content .submenu-button {
		padding: 20px 0px;
	}
	#menu .content a,
	#menu .content button {
		font-size: 18px;
	}
	#menu .content a:after,
	#menu .content .button:after,
	#menu .content .submenu-button:after {
		bottom: 10px;
	}
}
