.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	background-color: #00000080;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	z-index: 500;
}

.popup .container {
	background-color: #fff;
	border-radius: 10px;
	width: 100%;
	max-width: 800px;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 15px;
}

.popup.sm .container {
	max-width: 500px;
}

.popup .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	font-size: 24px;
}

.popup .header button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	width: 35px;
	height: 35px;
	background-color: var(--c2);
	color: var(--c2c);
}

.popup .content {
	display: flex;
	flex-direction: column;
	gap: 15px;
	overflow: auto;
}

.popup .button {
	width: 100%;
}
