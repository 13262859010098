.userboard {
  max-width: 700px;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.userboard h1 {
  margin: 0;
  text-align: center;
  font-size: 26px;
}

.userboard h1 .subtitle {
  font-size: 16px;
  opacity: 0.5;
}

.userboard .box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 15px;
}

.userboard .box.highlight {
  background-color: var(--c1);
  color: var(--c1c);
}

.userboard .box.columns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.userboard .box.text-l {
  font-size: 24px;
}

.userboard .box.text-xl {
  font-size: 26px;
}

.userboard form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.userboard .buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.userboard .buttons.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.userboard .buttons.horizontal button {
  width: auto;
}

.userboard .address-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userboard .menu {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: auto;
  flex-shrink: 0;
  padding: 10px 0;
}

.userboard .menu > * {
  background-color: #fff;
  color: #999;
  border: 1px solid #999;
  font-size: 14px;
  width: auto;
  padding: 5px 10px;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 5px;
}

/* width */
.userboard .menu::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
.userboard .menu::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
.userboard .menu::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
.userboard .menu::-webkit-scrollbar-thumb:hover {
  background: rgb(143, 143, 143);
}

.userboard .menu > *.selected {
  background-color: var(--c1);
  color: var(--c1c);
  border: 1px solid var(--c1);
}

.orders-list {
  display: grid;
  grid-template-columns: auto 100px 80px;
  gap: 10px;
  align-items: center;
}

.transactions-list {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  align-items: center;
}

.transactions-list .center {
  text-align: center;
}

.transactions-list .right {
  text-align: right;
}
