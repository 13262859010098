.confirm {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #00000050;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	z-index: 500;
}

.confirm .content {
	background-color: #ffffff;
	width: 100%;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 10px;
}

.confirm .content .title {
	font-size: 20px;
	font-weight: 500;
	text-align: center;
}

.confirm .content .message {
	text-align: center;
}

.confirm .content .buttons {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
}

.confirm .content button {
	border: 0;
	margin: 0;
	background-color: var(--c1c);
	color: var(--c1);
	border: 1px solid var(--c1);
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	border-radius: 5px;
	text-transform: uppercase;
}

.confirm .content button.success {
	background-color: var(--c1);
	color: var(--c1c);
}
