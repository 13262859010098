.shop-page .content {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: #eee;
}

@media (width >= 700px) {
  .shop-page .content {
    flex-direction: row;
  }
}

.shop-page .left {
  padding: 60px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (width >= 700px) {
  .shop-page .left {
    width: 400px;
  }
}

@media (width >= 768px) {
  .shop-page .content {
    padding: 100px 30px;
  }
  .shop-page .left {
    width: 50%;
  }
  .shop-page .right {
    width: 50%;
  }
}

@media (width >= 1024px) {
  .shop-page .left {
    width: 30%;
  }
  .shop-page .right {
    width: 70%;
  }
}

.shop-page .description {
  font-size: 12px;
}

.shop-page .buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.shop-page .buttons a {
  display: block;
  padding: 5px 10px;
  background-color: var(--c2);
  color: var(--c2c);
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
}

.shop-page .buttons a:nth-child(2) {
  color: var(--c2);
  border: 1px solid var(--c2);
  background-color: unset;
}

.shop-page .right {
  background-color: var(--c1);
  color: var(--c1c);
  flex-grow: 1;
  display: grid;
  align-content: space-between;
  padding: 60px;
}

.shop-page .columns {
  display: grid;
  gap: 30px;
}

@media (width >= 900px) {
  .shop-page .columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.shop-page .shop-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  background-color: #eee;
  padding: 10px 0;
}
.shop-page .shop-gallery img {
  width: 100%;
}

.shop-page-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.shop-page-card .icon {
  background-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-page-card .title {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
}

.shop-page-card .label {
  text-align: center;
}

.shop-page-card a {
  color: inherit;
  text-decoration: none;
}

.shop-page .more-info {
  border-top: 2px solid var(--c1c);
  margin-top: 30px;
  padding-top: 30px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.shop-page .more-info .icon {
  background-color: var(--c1c);
  color: var(--c1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.shop-page .more-info .text {
  font-size: 14px;
  flex-grow: 1;
}

.shop-page-footer {
  display: flex;
  flex-direction: column;
}

@media (min-width: 700px) {
  .shop-page-footer {
    flex-direction: row;
  }
}

.shop-page-footer > div {
  width: 100%;
}

.shop-page-footer .img {
  aspect-ratio: 4/3;
  background-position: center;
  background-size: cover;
}

.shop-page-footer #shop-map {
  aspect-ratio: 16/9;
  background-color: #eee;
}

.shop-page-footer #shop-map {
  aspect-ratio: 16/9;
  background-color: #eee;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 400 !important;
}
