@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

* {
	box-sizing: border-box;
}

:root {
	--c1: #494373;
	--c1c: #fff;
	--c2: #d50000;
	--c2c: #fff;
	--c3: #fff;
	--c3c: #000;
	--smoot: #a8a8a8;
	--header-h: 61px;
}

html {
	height: 100dvh;
}

body {
	height: 100%;
	margin: 0;
}

body,
button,
select,
textarea,
input {
	font-family: "Poppins", sans-serif;
}

a,
button,
select {
	color: inherit;
}
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 99%;
	background-position-y: 10px;
	color: inherit;
}

input[type="date"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	color: inherit;
}

#root {
	height: 100dvh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

#main {
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

#main > * {
	width: 100%;
}

.page {
	flex-grow: 1;
}

@media (max-width: 700px) {
	.hidden-small {
		display: none !important;
	}
}

@media (min-width: 701px) {
	.hidden-large {
		display: none !important;
	}
}

.small {
	font-size: 12px;
}

.title {
	font-weight: 600;
}

.css-13cymwt-control {
	border-color: #eee !important;
}

.plus-minus {
	display: grid;
	grid-template-columns: auto 40px auto;
	justify-items: center;
	align-items: center;
}

.plus-minus button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	padding: 0px;
	font-size: 12px;
	background-color: var(--c1);
	color: var(--c1c);
	border: 0;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	font-size: 22px;
}

.plus-minus button.disabled {
	opacity: 0.5;
}

#loading {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #00000090;
	padding: 15px;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100dvh;
	z-index: 5000;
}

#loading .content {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
}

.product-line {
	display: grid;
	grid-template-columns: auto 20px auto;
}

.product-line > *:nth-child(2) {
	justify-self: center;
}

.product-line > *:nth-child(3) {
	justify-self: end;
}

.label-line {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	align-items: center;
}

.close-button {
	display: flex;
	justify-content: flex-end;
}

.close-button button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	width: 35px;
	height: 35px;
	background-color: var(--c2);
	color: var(--c2c);
}

.spacer {
	margin-top: 10px;
	margin-bottom: 10px;
}

.text-center {
	text-align: center;
}

button {
	cursor: pointer;
}

.lock {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--c3);
}

.lock img {
	display: block;
	max-width: 200px;
}

.page .container {
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	padding: 15px;
}

.video {
	width: 100%;
	aspect-ratio: 16/9;
}

.video::after {
	content: "";
	width: 100%;
	aspect-ratio: 16/9;
	background-color: red;
}

.text-link {
	color: var(--c1);
	text-decoration: none;
}

.text-link:hover {
	text-decoration: underline;
}

.icon {
	display: flex;
	gap: 5px;
	align-items: center;
}

.cols {
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: space-between;
}

.screen-container {
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 700px) {
	.screen-container {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 900px) {
	.screen-container {
		padding-left: 40px;
		padding-right: 40px;
	}
}

@media (min-width: 1200px) {
	.screen-container {
		padding-left: 50px;
		padding-right: 50px;
	}
}

.smoot {
	color: var(--smoot);
	font-weight: 300;
}

.privacy {
	font-size: 12px;
	margin-bottom: 15px;
}

.privacy button {
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
	text-decoration: underline;
	font-size: 12px;
	color: var(--c1);
}

.marketing > * {
	margin-bottom: 15px;
}

.marketing a {
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
	text-decoration: underline;
	font-size: 16px;
	color: var(--c1);
	text-align: left;
	white-space: normal;
	word-wrap: break-word;
}

.error {
	color: red;
}

.success {
	color: green;
}

.leaflet-container {
	height: 100%;
}

#cookies {
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	width: 100%;
	z-index: 400;
	background-color: var(--c2);
	color: var(--c2c);
	padding: 30px;
	flex-wrap: wrap;
}

#cookies .title {
	font-size: 20px;
}

#cookies .content .text a {
	color: var(--c1);
}

#cookies .button-container {
	display: flex;
	align-items: center;
	gap: 15px;
}

#cookies .button-container button {
	text-wrap: nowrap;
}

.cookie-settings {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #00000095;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 401;
	width: 100%;
	height: 100dvh;
}

.cookie-settings .content {
	background-color: #fff;
	padding: 15px;
	width: 100%;
	max-width: 500px;
	max-height: 100%;
	overflow: auto;
	border-radius: 10px;
	gap: 10px;
	display: flex;
	flex-direction: column;
}

.cookie-settings .title {
	font-size: 20px;
}

.cookie-settings .text {
	font-size: 14px;
}

.cookie-settings .button-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.cookie-settings .button-container button {
	width: 100%;
}

.cookie-settings .checkbox-line {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f0f0f0;
	padding: 5px;
	font-size: 14px;
}

#page-header {
	background-color: var(--c1);
	color: var(--c1c);
	margin: 0;
	padding: calc(15px + 3vw);
	text-align: center;
	text-transform: uppercase;
}

.table {
	display: grid;
}

.table > div {
	border-bottom: 1px solid #eee;
	padding: 10px;
}

.table.table-3 {
	grid-template-columns: auto auto auto;
}

.table.table-4 {
	grid-template-columns: auto auto auto auto;
}

.table .table-head {
	background-color: #000;
	color: #fff;
	font-weight: 600;
	border-bottom: 0;
}

.mb-1 {
	margin-bottom: 5px;
}

.mb-2 {
	margin-bottom: 15px;
}

.mb-3 {
	margin-bottom: 30px;
}

.mt-1 {
	margin-top: 5px;
}

.mt-2 {
	margin-top: 15px;
}

.mt-3 {
	margin-top: 30px;
}

.expire-box {
	background-color: #fff3cd;
	border: 1px solid #ffeeba;
	font-size: 12px;
	margin-left: 10px;
	padding: 5px;
	border-radius: 5px;
}

.expire-box.block {
	display: block;
	margin-left: 0px;
	margin-top: 10px;
}

.news {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.news > a {
	background-color: var(--c1o);
	color: var(--c1c);
	padding: 15px;
	border-radius: 15px;
	text-decoration: none;
	display: flex;
	gap: 15px;
}

.news .img {
	width: 100%;
	max-width: 25%;
	flex-shrink: 0;
}

.news .img img {
	width: 100%;
}

.news .content {
	color: var(--c1);
	display: grid;
	gap: 8px;
}

.news .date {
	font-size: 12px;
}

.news .description {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-size: 14px;
}
.news .description p {
	margin: 0px;
}

.news-single .img {
	text-align: center;
	height: 300px;
	margin-bottom: 15px;
}

.news-single .img img {
	max-width: 100%;
	max-height: 100%;
}

.small-scroll::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

.small-scroll::-webkit-scrollbar-track {
	background: #eee;
}

.small-scroll::-webkit-scrollbar-thumb {
	background: #ccc;
}

.small-scroll::-webkit-scrollbar-thumb:hover {
	background: rgb(143, 143, 143);
}

.tooltip {
	background-color: #000;
	color: #fff;
	padding: 2px;
	font-size: 12px;
	border-radius: 5px;
	position: absolute;
	bottom: 25px;
	display: none;
}

.header-left {
	display: flex;
	gap: 5px;
	align-items: flex-end;
}

.brands-switch {
	border: 0;
	padding: 0;
	background-color: transparent;
}

.brands-switch svg {
	font-size: 20px;
}

.mobile-container {
	display: flex;
	gap: 10px;
}

.mobile-container > *:nth-child(2) {
	flex-grow: 1;
}

.mobile-container select {
	max-width: 120px;
}

.expire {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	background-color: #00000095;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 11;
}

.expire .content {
	background-color: #fff;
	padding: 30px;
	border-radius: 5px;
	border: 10px solid var(--c2);
	max-width: 350px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
