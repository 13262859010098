.product-sheet h1 {
	font-size: 30px;
	line-height: 30px;
	margin: 0;
}

.product-sheet .product-img {
	max-width: 100%;
	max-height: 250px;
	margin: 0 auto;
	display: block;
}

.product-sheet .description {
	border-bottom: 1px solid #eee;
	padding-bottom: 15px;
}

.product-sheet .allergens {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

.product-sheet .allergens > * {
	width: 40px;
	height: 40px;
	background-color: #ccc;
	border-radius: 100%;
	padding: 5px;
}

.variant-line {
	display: grid;
	gap: 10px;
	align-items: center;
	grid-template-columns: auto 70px;
}

@media (width >= 380px) {
	.variant-line {
		grid-template-columns: 90px auto;
	}
}

.variant-line > *:nth-child(1) span {
	margin-left: 10px;
	font-size: 12px;
}

.variant-line span {
	font-size: 12px;
	margin-left: 5px;
	color: #a8a8a8;
}

.product-sheet .error {
	color: red;
	display: none;
}

.product-sheet .allergens-ingredients {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

@media (width >= 700px) {
	.product-sheet .allergens-ingredients {
		flex-direction: row;
	}
	.product-sheet .allergens-ingredients > * {
		flex-grow: 1;
	}
	.product-sheet .allergens-ingredients > *:nth-child(2) {
		border-left: 1px solid #eee;
		width: 70%;
		padding-left: 15px;
	}
}

.ingredient-line {
	display: flex;
	gap: 10px;
	align-items: center;
	padding-right: 15px;
}

.ingredient-line input {
	width: 20px;
	height: 20px;
}

.product-sheet .variants-categories {
	display: grid;
	gap: 10px;
	padding-right: 15px;
}

.product-sheet .variants-categories.radio {
	display: grid;
	gap: 10px;
	padding-right: 15px;
}

.product-sheet .variants-categories.radio .variant-line {
	display: flex;
}

.product-sheet .variants-categories.radio button {
	border: 0;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 0;
	background-color: #ccc;
	border: 4px solid #ccc;
}

.product-sheet .variants-categories.radio button.checked {
	background-color: var(--c1);
}

.product-sheet .min_max {
	margin-bottom: 5px;
}

@media (width >= 700px) {
	.product-sheet .variants-categories {
		grid-template-columns: 1fr 1fr;
	}

	.product-sheet .variants-categories.radio {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
