.brands {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.brands .content {
}

.brands .title {
	text-align: center;
}

.brands .list {
	display: grid;
	gap: 50px;
}

.brands button {
	border: 0;
	margin: 0;
	padding: 0;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
}

.brands button img {
	width: 100%;
}
