input,
textarea,
select {
	border: 1px solid #eee;
	border-radius: 5px;
	padding: 10px;
	font-size: 16px;
	width: 100%;
}

textarea {
	resize: none;
}

.input-group {
	position: relative;
}

.input-group:has(button) {
	display: flex;
}

.input-group input:has(+ button) {
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	border-right: 0;
}

.input-group label {
	position: absolute;
	top: -1px;
	left: 4px;
	font-size: 12px;
}

.input-group.type-tel label {
	left: 134px;
}

.input-group button {
	border: 1px solid var(--c1);
	background-color: var(--c1c);
	color: var(--c1);
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.checkbox {
	display: flex;
	align-items: center;
	gap: 10px;
}

.checkbox input {
	width: auto;
	width: 20px;
	height: 20px;
}

.password-field {
	flex-grow: 1;
}

.password-field button {
	border-radius: 5px;
}

.password-field .password-errors {
	background-color: #eee;
	margin-top: 10px;
	padding: 10px;
	font-size: 12px;
}
