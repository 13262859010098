#footer {
}

/* Pre footer */
#footer .prefooter {
  padding: 15px;
  background: #000;
  color: #fff;
  display: grid;
  gap: 10px;
}

@media (min-width: 701px) {
  #footer .prefooter {
    grid-template-columns: 1fr 4fr;
  }
}

#footer .prefooter .socials {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 25px;
  justify-content: start;
}

#footer .prefooter .socials a {
  color: #fff;
  text-decoration: none;
  opacity: 0.7;
}

#footer .prefooter .socials a:hover {
  opacity: 1;
}
/* Main footer */

#footer .prefooter .menu-prefooter,
#footer .mainfooter .menu-footer {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 0px;
  align-items: center;
  flex-wrap: wrap;
}

#footer .prefooter .menu-prefooter a,
#footer .prefooter .menu-prefooter button,
#footer .mainfooter .menu-footer a,
#footer .mainfooter .menu-footer button {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

#footer .prefooter .menu-prefooter a:hover,
#footer .mainfooter .menu-footer a:hover {
  text-decoration: underline;
}

#footer .prefooter .menu-prefooter.menu-footer-2 {
  justify-content: start;
}

#footer .prefooter .menu-prefooter .submenu,
#footer .mainfooter .menu-footer .submenu {
  padding: 5px 5px 0 5px;
}

#footer .prefooter .menu-prefooter .submenu > *,
#footer .mainfooter .menu-footer .submenu > * {
  color: #000;
  white-space: nowrap;
  display: block;
  margin-bottom: 5px;
}
#footer .menu-footer.menu-footer-1 a,
#footer .menu-footer.menu-footer-1 div {
  padding-bottom: 10px;
}

#footer .mainfooter {
  padding: 30px 15px 0;
  background: #000;
  color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 701px) {
  #footer .mainfooter {
    flex-direction: row;
    grid-template-columns: 1fr 4fr;
  }
  #footer .menu-footer.menu-footer-1 a,
  #footer .menu-footer.menu-footer-1 div {
    padding-bottom: 40px;
  }
  #footer .prefooter .socials {
    justify-content: center;
  }
}
#footer .mainfooter .footer-logo {
  text-align: center;
}
#footer .mainfooter .footer-logo img {
  max-width: 200px;
}

#footer .mainfooter .footer-shops {
  flex-grow: 1;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 500px) {
  #footer .mainfooter .footer-shops {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 701px) {
  #footer .mainfooter .footer-shops {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 901px) {
  #footer .mainfooter .footer-shops {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  #footer .mainfooter .footer-shops {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

#footer .mainfooter .footer-shops a {
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

#footer .mainfooter .footer-shops a .title {
  color: #fff;
  font-size: 14px;
}

/* Sub footer */
#footer .subfooter {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  text-align: center;
  background-color: var(--c1);
  color: var(--c1c);
  padding: 15px;
}
#footer .subfooter .info {
  display: flex;
  gap: 0px 10px;
  flex-wrap: wrap;
}

#footer .subfooter .info > * {
  white-space: nowrap;
}

#footer .subfooter a {
  color: #fff;
  text-decoration: none;
}

#footer .subfooter a:hover {
  text-decoration: underline;
}
#footer .subfooter .apps {
  display: flex;
  justify-content: start;
  gap: 5px;
}
@media (min-width: 701px) {
  #footer .subfooter .apps {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  #footer .subfooter {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
  #footer .subfooter .info {
    gap: 10px;
  }
}
