.slides {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (width < 700px) {
  .slides .slide {
    min-height: auto !important;
  }
}

.slides .slide .img {
  aspect-ratio: 1;
  background-size: cover;
  background-position: center;
}

.slides .slide .content {
  padding: calc(15px + 3vw);
}

@media (width >= 700px) {
  .slides .slide .img {
    aspect-ratio: auto;
  }

  .slides .slide {
    min-height: calc(100vh - 61px);
    display: flex;
    align-items: center;
    align-items: stretch;
  }
  .slides .slide.left {
    flex-direction: row-reverse;
  }

  .slides .slide.left .img,
  .slides .slide.right .img {
    flex-grow: 1;
  }

  .slides .slide.left .content {
    width: 100%;
    max-width: 40%;
    align-self: center;
    text-align: right;
  }

  .slides .slide.right .content {
    width: 100%;
    max-width: 40%;
    align-self: center;
    text-align: left;
  }
}

.slides .slide.center {
  padding: calc(15px + 3vw);
}

.slides .slide.center .content {
  text-align: center;
}

.slides .slide.center {
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  display: flex;
}

.slides .slide .content .title {
  margin-bottom: 20px;
  font-size: calc(22px + 2vw);
  line-height: calc(22px + 2vw);
}

.slides .slide .content .text {
  font-size: calc(12px + 1vw);
  line-height: calc(20px + 1vw);
  margin: 5px 0;
}

.slides .slide .content button {
  border: 0;
  background-color: var(--c1);
  color: var(--c1c);
  border-radius: 10px;
  padding: 10px 20px;
  font-size: calc(12px + 1vw);
}
