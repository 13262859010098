.promo-levels {
	background-color: var(--c1);
	color: var(--c1c);
	padding: 10px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.promo-levels .value {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	font-weight: 600;
	color: var(--c1);
}

.promo-levels-line.title {
	border-bottom: 1px solid #fff;
	padding-bottom: 5px;
}

.promo-levels .bar {
	height: 25px;
	background-color: var(--c1);
	opacity: 0.5;
	position: absolute;
	border-radius: 5px;
}

.promo-levels .promo-levels-line {
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	font-size: 14px;
	line-height: 18px;
}

.promo-levels .promo-levels-line > *:nth-child(2) {
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
}
.promo-levels .promo-levels-line svg {
	height: 20px;
}

.promo-levels .level {
	position: absolute;
}

.promo-levels .level .separator {
	width: 2px;
	background-color: var(--c1);
	height: 25px;
}

.promo-levels .level .label {
	display: none;
	font-size: 12px;
	background-color: red;
	padding: 3px;
	border-radius: 5px;
	white-space: nowrap;
	position: absolute;
	top: -2px;
	left: -10px;
	transform-origin: top left;
	transform: rotate(-90deg);
}

.promo-levels .promo-activated {
	font-weight: 600;
}
