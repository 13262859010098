.cms-popups {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	background-color: #00000090;
	height: 100dvh;
	width: 100%;
	z-index: 400;
}

.cms-popup-content {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	padding: 15px;
	background-color: #fff;
	max-height: 100%;
	overflow: auto;
	max-width: 600px;
}

.cms-popup-content .img img {
	max-width: 100%;
	max-height: 300px;
}
