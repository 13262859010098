.shops-template-2 #page-header {
  padding: 15px;
}

.shops-template-2 .list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;
}

.shops-template-2 .box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.shops-template-2 .addinfo-label {
  font-size: 12px;
}
.shops-template-2 .addinfo-label a {
  color: var(--c1);
  text-transform: uppercase;
  font-weight: bold;
}
@media (width >= 700px) {
  .shops-template-2 .box {
    flex-direction: row;
  }

  .shops-template-2 .box:nth-child(even) {
    flex-direction: row-reverse;
  }
}

.shops-template-2 .box .img {
  min-height: 350px;
  flex-grow: 1;
  background-position: center;
  background-size: cover;
}

.shops-template-2 .box .info {
  padding: 30px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

@media (width >= 700px) {
  .shops-template-2 .box .info {
    width: 400px;
  }
}

.shops-template-2 .box .address {
  font-size: 12px;
}

.shops-template-2 .box .title {
  font-size: 24px;
}

.shops-template-2 .box .contacts {
  font-size: 14px;
}

.shops-template-2 .box .times {
  font-size: 14px;
}

.shops-template-2 .box .times .title {
  font-size: 16px;
  text-transform: uppercase;
}

.shops-template-2 .box a {
  color: inherit;
  text-decoration: none;
}

.shops-template-2 .box .buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.shops-template-2 .box .buttons a {
  display: block;
  background-color: unset;
  border-radius: 5px;
  color: var(--c2);
  font-weight: 700;
  padding: 5px 10px;
  border: 1px solid var(--c2);
}

.shops-template-2 .box .buttons a:nth-child(1) {
  background-color: var(--c2);
  border-radius: 5px;
  color: var(--c2c);
}

.shops-template-2 .box .buttons a:last-child {
  background-color: #000;
  color: #fff;
  border-color: #000;
}
.shops-template-2 .addinfo-label {
  font-size: 12px;
}
.shops-template-2 .addinfo-label a {
  color: var(--c1);
  text-transform: uppercase;
  font-weight: bold;
}
.shops-menu {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  background-color: var(--c1);
  position: sticky;
  top: 0px;
  width: 100%;
  overflow: auto;
}

.shops-menu > * {
  display: block;
  padding: 5px 10px;
  background-color: var(--c2);
  color: var(--c2c);
  border-radius: 5px;
  font-weight: bold;
  border: 0;
  text-decoration: none;
  border: 1px solid var(--c1c);
}
.shops-menu > *:focus {
  background-color: var(--c2c);
  color: var(--c1);
}
