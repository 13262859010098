#cart {
	display: none;
	padding: 15px 0;
}

#cart .container {
	flex-direction: column;
	gap: 15px;
	background-color: #fff;
	border-radius: 10px;
	padding: 15px;
}

#cart .content {
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

#cart .line {
	display: grid;
	grid-template-columns: auto 100px 110px;
}

#cart .line > *:nth-child(2) {
	justify-self: center;
}

#cart .line > *:nth-child(3) {
	justify-self: end;
	text-align: right;
}

#cart .line.subtotal {
	font-weight: 600;
}

#cart .line.total {
	font-weight: 600;
	font-size: 20px;
}

.checkout-fixed {
	position: -webkit-sticky;
	position: -moz-sticky;
	position: -o-sticky;
	position: -ms-sticky;
	position: sticky;
	bottom: 0;
	background-color: #fff;
	padding: 15px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.checkout-fixed button {
	display: block;
	padding: 6px 10px;
	color: var(--c3c);
	opacity: 1;
	border: 0;
	border-radius: 5px;
	background-color: var(--c1);
	color: var(--c1c);
	font-size: 16px;
	width: 100%;
	text-decoration: none;
	text-align: center;
}

@media (max-width: 899px) {
	#cart {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100dvh;
		background-color: #fff;
		z-index: 400;
	}
}

@media (min-width: 900px) {
	#cart {
		position: sticky;
		display: block;
		align-self: start;
		bottom: auto;
		top: 60px;
		height: calc(100dvh - 240px);
		overflow: hidden;
	}

	.checkout-fixed {
		display: none;
	}

	#cart .close-button {
		display: none;
	}
}

#cart .container {
	max-height: 100%;
	overflow: hidden;
	display: flex;
}
