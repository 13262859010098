.store-columns {
	background-color: #eeeeee60;
}

@media (min-width: 900px) {
	.store-columns {
		display: grid;
		grid-template-columns: auto 450px;
		gap: 15px;
	}
	.three-columns {
		grid-template-columns: 250px auto 450px;
	}
}

#store {
	padding: 15px 0;
	flex-grow: 1;
}

#store h1 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 26px;
}

#store h2 {
	padding-top: 15px;
	margin: 0;
	font-size: 20px;
}

.product-container h3 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 16px;
	word-break: break-word;
}

.product-container p {
	margin: 0;
	font-size: 14px;
	margin-bottom: 10px;
}

#store .products {
	margin-bottom: 15px;
}

@media (min-width: 1200px) {
	#store .products {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px;
	}
}

@media (min-width: 1500px) {
	#store .products {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 10px;
	}
}

.product-container {
	padding: 10px;
	border-bottom: 1px solid #eee;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.product-box-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-box-footer .buttons {
	display: flex;
	align-items: center;
	gap: 10px;
}

.product-container .product-add-button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	width: 36px;
	height: 36px;
	background-color: var(--c1);
	color: var(--c1c);
	border-radius: 18px;
	font-size: 18px;
	padding: 7px;
}

.product-container .product {
	background-color: #fff;
	border: 0;
	display: flex;
	justify-content: space-between;
	gap: 10px;
	font-size: 16px;
	text-align: left;
	width: 100%;
	flex-grow: 1;
	padding: 0;
}

.product-container .product .info {
	flex-grow: 1;
}

.product-container .product .info .badge-container:has(div) {
	margin: 10px 0;
}

.product-container .product .img {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	border: 1px solid #eee;
	padding: 5px;
}

.product-container .product .img img {
	max-width: 100%;
	max-height: 100%;
}

.product-container .product .description {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

#store-header {
	padding-top: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid #eee;
	display: flex;
	gap: 25px;
	align-items: center;
	flex-direction: column;
}

@media (min-width: 701px) {
	#store-header {
		flex-direction: row;
	}
}

#store-header .content {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.store-header-title {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;
}

@media (min-width: 701px) {
	.store-header-title {
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: flex-end;
	}
}

.store-header-title .title {
	line-height: 30px;
}

#store-header .img {
	border-radius: 15px;
	overflow: hidden;
	height: 150px;
	aspect-ratio: 4/3;
	background-position: center;
	background-size: cover;
	flex-shrink: 0;
	transition: height 0.2s ease-in-out;
	border: 5px solid var(--c1);
}

@media (min-width: 1200px) {
	#store-header .img {
		height: 200px;
	}
}

#store-header .content {
	flex-grow: 1;
}

#store-header .title {
	font-size: 30px;
	display: flex;
	align-items: center;
}

#store-header .title svg {
	font-size: 30px;
	margin-right: 10px;
}

#store-header .delivery-type-selector {
	margin-top: 5px;
	margin-bottom: 5px;
	max-width: 450px;
}

#store-header .delivery-type-selector button {
	font-size: 14px;
}

#store-header .delivery-type-selector button svg {
	font-size: 20px;
}

#store-header .lines-container {
	width: fit-content;
	display: grid;
	align-items: center;
	gap: 10px;
}

@media (min-width: 701px) {
	#store-header .lines-container {
		grid-template-columns: auto auto;
	}
}

#store-header .icon-line {
	display: grid;
	grid-template-columns: 20px auto;
	gap: 5px;
	align-items: center;
}

#store-header .icon-line > *:nth-child(1) {
	justify-self: center;
}

#store-header .icon-line svg {
	color: var(--c1);
}

#store-header > button {
	padding: 0px;
	border: 0;
	background-color: transparent;
	font-size: 16px;
	display: block;
}

#store-popup {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #00000080;
	width: 100%;
	height: 100dvh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 500;
	padding: 15px;
}

#store-popup .content {
	background-color: #fff;
	border-radius: 10px;
	padding: 15px;
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: auto;
	max-height: 100%;
}

#store-popup .content .address-buttons {
	display: flex;
}

#store-popup .content .address-buttons > button {
	display: block;
	padding: 6px 10px;
	text-decoration: none;
	color: var(--c3c);
	border: 1px solid #eee;
	background-color: #fff;
	color: #000;
	font-size: 16px;
	text-align: left;
	position: relative;
	flex-grow: 1;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

#store-popup .content .address-buttons .buttons {
	display: grid;
	grid-template-rows: 1fr 1fr;
	flex-basis: 40px;
}

#store-popup .content .address-buttons .buttons button {
	color: var(--c1c);
	background-color: var(--c1);
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
}

#store-popup .content .address-buttons .buttons button:nth-child(1) {
	border-top-right-radius: 5px;
	border-bottom: 1px solid var(--c1c);
}
#store-popup .content .address-buttons .buttons button:nth-child(1) svg {
	font-size: 20px;
}

#store-popup .content .address-buttons .buttons button:nth-child(2) {
	border-bottom-right-radius: 5px;
}

#store-popup .content .address-buttons .buttons button:nth-child(2) svg {
	font-size: 16px;
}
#store-popup .content button .error {
	background-color: #ff000020;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: end;
	justify-content: flex-end;
	font-size: 14px;
	font-weight: 600;
	padding: 5px;
}

#store-popup .content .error-box {
	background-color: #ff000030;
	border: 1px solid red;
	color: red;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
	padding: 5px;
	white-space: pre-wrap;
}

#store-popup .box {
	border-bottom: 1px solid var(--c1);
	padding-bottom: 10px;
}

#store-popup .box .box-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--c1);
	margin-bottom: 10px;
	font-weight: 600;
	font-size: 20px;
}

#store-popup .box .box-header button {
	color: var(--c1);
	font-weight: 600;
	background-color: transparent;
	border: 0;
	font-size: 16px;
	display: flex;
	align-items: center;
}

#store-popup .box .box-header button svg {
	margin-left: 5px;
	margin-right: 0;
	font-size: 18px;
}

.badge-container {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

.badge-container > * {
	border-radius: 5px;
	background-color: var(--c2);
	color: var(--c2c);
	padding: 2px 5px;
	font-size: 12px;
}

.delivery-type-selector {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
}

.delivery-type-selector button {
	border: 0;
	background-color: transparent;
	color: #aaa;
	font-size: 14px;
	padding: 5px 10px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	height: 60px;
	background-color: #eee;
	border-radius: 5px;
}

.delivery-type-selector button > div {
	text-align: center;
	width: fit-content;
}

.delivery-type-selector button svg {
	font-size: 18px;
	margin: 0;
}

.delivery-type-selector button.active {
	background-color: var(--c1);
	color: var(--c1c);
	border: 1px solid var(--c1);
}

.tags {
	display: flex;
	gap: 5px;
	flex-wrap: wrap;
}

.tags > div {
	color: var(--c1);
	background-color: var(--c1o);
	font-size: var(--extrasmall);
	padding: 2px 5px;
	border-radius: 5px;
	font-size: 12px;
}

.product-container .product .info .tags:has(div) {
	margin: 10px 0;
}

.product-container .allergens {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin: 10px 0;
}

.product-container .allergens > * {
	width: 20px;
	height: 20px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
}

.product-container .allergens svg {
	width: 100px;
}

#store .src {
	position: relative;
	padding: 10px 0;
	background-color: #f9f9f9;
	position: sticky;
	top: 0;
	z-index: 10;
}
#store .src input {
	background-color: unset;
	border-right: 0px;
	border-left: 0px;
	border-top: 0px;
	border-bottom: 2px solid #ccc;
	border-radius: 0px;
	font-weight: 100;
	padding-left: 30px;
}

#store .src input:focus-visible {
	outline: 0px;
	border-bottom: 2px solid var(--c1);
}
#store .src span {
	position: absolute;
	top: 20px;
	left: 0px;
	color: #ccc;
}

#store .src button {
	position: absolute;
	top: 20px;
	right: 8px;
	padding: 0;
	margin: 0;
	font-size: 20px;
	font-weight: bold;
	border: 0px solid #ccc;
	background-color: unset;
	color: var(--c1);
	border-radius: 100px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
@media (max-width: 900px) {
	#store .src {
		top: 30px;
	}
}

.related-products-popup .products-list {
	display: grid;
	gap: 10px;
}

@media (min-width: 600px) {
	.related-products-popup .products-list {
		grid-template-columns: 1fr 1fr;
	}
}

.product-discount-dates {
	font-size: 12px;
	background-color: var(--c1);
	color: var(--c1c);
	padding: 2px;
	text-align: center;
}
