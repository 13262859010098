.submenu-container.bubble {
	position: relative;
}

.submenu-container.bubble .submenu {
	position: absolute;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 5px;
	margin-top: 5px;
	display: none;
}

.submenu-container.accordion {
}

.submenu-container.accordion .button {
	width: 100%;
}

.submenu-container.accordion .submenu {
	padding-left: 15px;
}

.submenu-container.accordion .submenu > * {
	margin-top: 5px;
}

.submenu-button {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: space-between;
}
