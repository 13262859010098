.categories-menu-1-container {
	flex-shrink: 0;
	position: sticky;
	top: 0px;
	background-color: #fff;
	z-index: 400;
}

.categories-menu-1 {
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #eee;
	display: flex;
	gap: 10px;
	overflow-x: auto;
	/*scroll-snap-type: x mandatory;
	scroll-padding-left: 0px;*/
}

.categories-menu-1 button {
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	border-radius: 5px;
	border: 0;
	color: var(--c1);
	background-color: var(--c1c);
	font-size: 16px;
	padding: 6px 10px;
	scroll-snap-align: start;
	text-transform: uppercase;
}

.categories-menu-1 button.active {
	color: var(--c1c);
	background-color: var(--c1);
}

/* width */
.categories-menu-1::-webkit-scrollbar {
	width: 10px;
	height: 5px;
}

/* Track */
.categories-menu-1::-webkit-scrollbar-track {
	background: #eee;
}

/* Handle */
.categories-menu-1::-webkit-scrollbar-thumb {
	background: #ccc;
}

/* Handle on hover */
.categories-menu-1::-webkit-scrollbar-thumb:hover {
	background: rgb(143, 143, 143);
}
