.featured-title {
	text-align: center;
	font-size: calc(20px + 2vw);
	font-weight: normal;
	margin: 0;
	padding: 15px 0;
}

.featured {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	justify-content: center;
	padding-bottom: 15px;
}

@media (width >= 600px) {
	.featured .card {
		grid-template-columns: repeat(2, 1fr);
		flex: 0 0 calc(50% - 23px);
	}
}

@media (width >= 900px) {
	.featured .card {
		grid-template-columns: repeat(2, 1fr);
		flex: 0 0 calc(33% - 23px);
	}
}

.featured > .card {
	border-radius: 10px;
	background-color: var(--c1);
	color: var(--c1c);
	padding: 30px;
	box-shadow: 5px 5px 10px #00000020;
	text-decoration: none;
	text-align: center;
}

.featured .card .img {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
	background-color: #fff;
	border-radius: 15px;
	padding: 15px;
	aspect-ratio: 4/3;
}

.featured .card .img img {
	max-width: 100%;
	max-height: 100%;
}

.featured .card .title {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 20px;
}

.featured .card .description {
	font-size: 14px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
