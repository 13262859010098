.booking {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.booking .label {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 5px;
}

.booking .form-grid {
	display: grid;
	gap: 10px;
	grid-template-columns: 1fr 1fr;
	align-items: flex-start;
}

.booking .buttons-list {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.booking .buttons-list button {
	font-size: 16px;
	font-weight: bold;
	border: 0;
	border: 1px solid var(--c1);
	background-color: var(--c1c);
	border-radius: 5px;
	padding: 10px;
	color: var(--c1);
	text-transform: uppercase;
}

.booking .buttons-list button.selected {
	border: 1px solid var(--c1);
	background-color: var(--c1);
	color: var(--c1c);
}

.booking .buttons-list button.disabled {
	border: 1px solid #eee;
	background-color: #eee;
	color: #999;
}

.booking .buttons-list button .subtitle {
	font-size: 14px;
	text-transform: capitalize;
	opacity: 0.9;
}

.booking .buttons-list.square button {
	width: 42px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.booking .checkout-button {
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	border: 0;
	border: 1px solid var(--c1);
	background-color: var(--c1);
	border-radius: 5px;
	padding: 10px;
	color: var(--c1c);
	text-transform: uppercase;
}

.booking .checkout-button.disabled {
	border: 1px solid var(--c1);
	background-color: var(--c1c);
	color: var(--c1);
}
