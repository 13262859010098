.shops {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

@media (width >= 700px) {
	.shops-2 {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.shops-3 {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

@media (width >= 900px) {
	.shops-3 {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.shop-box {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.shop-box .box-header {
	text-align: center;
}

.shop-box .address {
	font-size: 12px;
}

.shop-box .box-header .title {
	font-size: 18px;
	text-transform: uppercase;
	color: var(--c1);
}

.shop-box .box {
	background-color: var(--c1);
	color: var(--c1c);
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	border-radius: 10px;
	flex-grow: 1;
}

.shop-box .box .contacts {
	display: flex;
	gap: 30px;
	font-size: 14px;
}

.shop-box .box .times {
	font-size: 14px;
	text-align: center;
}

.shop-box .box .times .title {
	font-size: 16px;
	text-transform: uppercase;
	color: var(--c1c);
}

.shop-box .box a {
	color: var(--c1c);
	text-decoration: none;
}

.shop-box .img {
	flex-grow: 1;
}

.shop-box .img img {
	max-width: 100%;
}

.shop-box .box .buttons {
	display: flex;
	gap: 15px;
}

.shop-box .box .buttons a {
	display: block;
	padding: 5px 10px;
	background-color: var(--c2);
	color: var(--c2c);
	border-radius: 5px;
	font-weight: bold;
}
