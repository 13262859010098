.button {
	display: block;
	padding: 6px 10px;
	text-decoration: none;
	border-radius: 5px;
	background-color: #fff;
	color: #000;
	border: 1px solid #eee;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
}

.button-primary {
	background-color: var(--c1);
	color: var(--c1c);
	border: 0;
}

.button-primary.button-icon i {
	color: var(--c1c);
}

.button-secondary {
	background-color: var(--c2c);
	color: var(--c2);
	border-color: var(--c2);
}

.button.button-block {
	width: 100%;
}

.button-icon {
	display: flex;
	gap: 10px;
	align-items: center;
	width: 100%;
}

.button-icon svg {
	color: var(--c1);
}

.button-icon > div {
	flex-grow: 1;
}

.buttons-group {
	display: grid;
	gap: 10px;
	margin-top: 10px;
}

.buttons-group-horizontal {
	grid-template-columns: repeat(2, 1fr);
}
