#header {
	background-color: var(--c3);
	border-bottom: 1px solid #eee;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

#header .logo {
	display: block;
	padding: 10px 0;
	text-decoration: none;
	color: var(--c3c);
}

#header .logo img {
	display: block;
	max-height: 40px;
}

#header .menu {
	display: flex;
	align-items: center;
	gap: 10px;
}

#header .button {
	width: auto;
}
#header .button.button-icon {
	padding: 11px 10px 10px 10px;
	line-height: 11px;
}
#header .button {
	padding: 7px 10px;
}

.select-languages {
	position: relative;
}

.select-languages button {
	display: flex;
	gap: 5px;
	align-items: center;
	background-color: transparent;
	padding: 0px 10px;
	border: 1px solid #eee;
	font-size: 16px;
	border-radius: 5px;
	height: 39px;
}

.select-languages button img {
	height: 20px;
}

.select-languages .submenu {
	position: absolute;
	right: 0;
	top: 45px;
	background-color: #fff;
	padding: 10px;
	border-radius: 5px;
	z-index: 1;
	border: 1px solid #eee;
	display: flex;
	flex-direction: column;
	gap: 5px;
}
