#categories-menu-2-button {
	flex-shrink: 0;
	position: sticky;
	top: 0px;
	background-color: var(--c1);
	color: var(--c1c);
	z-index: 400;
	padding: 5px;
	font-size: 18px;
	border: 0;
}

@media (width >= 900px) {
	#categories-menu-2-button {
		display: none;
	}
}

.categories-menu-2 {
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: auto;
	background-color: #f9f9f9;
}

@media (width < 900px) {
	.categories-menu-2 {
		position: fixed;
		top: 0;
		left: 0;
		width: 250px;
		height: 100dvh;
		z-index: 400;
		padding: 15px;
		box-shadow: 0 5px 10px #00000040;
		display: none;
	}
}

@media (width >= 900px) {
	.categories-menu-2 {
		position: sticky;
		top: 0px;
		align-self: start;
		padding: 15px 15px 15px 0;
		height: calc(100dvh - 61px);
	}
}

.categories-menu-2 .course {
	font-weight: 600;
	font-size: 20px;
	margin-top: 15px;
}

.categories-menu-2 .list {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.categories-menu-2 button {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	border: 0;
	color: var(--c1);
	background-color: var(--c1c);
	font-size: 16px;
	padding: 6px 10px;
	scroll-snap-align: start;
	text-transform: uppercase;
}

.categories-menu-2 button.active {
	color: var(--c1c);
	background-color: var(--c1);
}

.categories-menu-2 .close-button {
	width: 30px;
	height: 30px;
	background-color: var(--c2);
	color: var(--c2c);
	align-self: flex-end;
}

@media (width >= 900px) {
	.categories-menu-2 .close-button {
		display: none;
	}
}
