#CookieManClient table tbody > tr:nth-child(odd) > td,
table tbody > tr:nth-child(odd) > th {
  background-color: unset !important;
}
div[ML_CookieMan_Client="Content"] :not(i) {
  all: unset;
}
#CookieManClient table:not(.-unstyle) td,
table:not(.-unstyle) th,
.wp-block-table:not(.-unstyle) td,
.wp-block-table:not(.-unstyle) th {
  vertical-align: middle !important;
}

.cookieman-form-modal-content :not(i) {
  all: unset;
}

div[ML_CookieMan="Container"] i[class*="fa"] {
  /*display: inline-block;*/
  font-size: 12px;
  width: unset;
  height: unset;
  line-height: unset;
  top: unset;
  word-spacing: unset;
  position: unset;
  text-align: unset;
  vertical-align: unset;
  max-width: unset;
  display: none;
}

/* forzature css per Modal Form aggiunte il 06/10/2020 */
div[ML_CookieMan_Form="ModalFooter"] tbody {
  background-color: transparent !important;
}

div[ML_CookieMan_Form="ModalFooter"] table {
  border: none !important;
  margin: 0px !important;
}

div[ML_CookieMan_Form="ModalFooter"] td {
  border: none !important;
  padding: 0px !important;
}

div[ML_CookieMan_Form="CloseButton"] table {
  height: 100% !important;
}

form[ml_cookieman_form="FullContainer"] {
  font-size: initial !important;
  line-height: initial !important;
}

tr[ml_cookieman_form_key="trExtendedTextContainer"] h3 {
  font-size: initial !important;
}

/**/

div[ML_CookieMan="Container"] * {
  box-sizing: border-box;
}

tr[ml_cookieman_form_key="trExtendedTextContainer"] span {
  line-height: 1.4 !important;
}

/* BEGIN user agent*/
div[ML_CookieMan="Container"] p {
  display: block;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1__qem;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

div[ML_CookieMan="Container"] layer {
  display: block;
}

div[ML_CookieMan="Container"] article,
div[ML_CookieMan="Container"] aside,
div[ML_CookieMan="Container"] footer,
div[ML_CookieMan="Container"] header,
div[ML_CookieMan="Container"] hgroup,
div[ML_CookieMan="Container"] main,
div[ML_CookieMan="Container"] nav,
div[ML_CookieMan="Container"] section {
  display: block;
}

div[ML_CookieMan="Container"] marquee {
  display: inline-block;
}

div[ML_CookieMan="Container"] address {
  display: block;
}

div[ML_CookieMan="Container"] blockquote {
  display: block;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}

div[ML_CookieMan="Container"] figcaption {
  display: block;
}

div[ML_CookieMan="Container"] figure {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px;
}

div[ML_CookieMan="Container"] q {
  display: inline;
}

div[ML_CookieMan="Container"] q:before {
  content: open-quote;
}

div[ML_CookieMan="Container"] q:after {
  content: close-quote;
}

div[ML_CookieMan="Container"] center {
  display: block;
  /* special centering to be able to emulate the html4/netscape behaviour */
  text-align: -webkit-center;
}

div[ML_CookieMan="Container"] hr {
  display: block;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  border-style: inset;
  border-width: 1px;
}

div[ML_CookieMan="Container"] map {
  display: inline;
}

div[ML_CookieMan="Container"] video {
  object-fit: contain;
}
/* heading elements */
div[ML_CookieMan="Container"] h1 {
  display: block;
  font-size: 2em;
  -webkit-margin-before: 0.67__qem;
  -webkit-margin-after: 0.67em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

div[ML_CookieMan="Container"] :-webkit-any(article, aside, nav, section) h1 {
  font-size: 1.5em;
  -webkit-margin-before: 0.83__qem;
  -webkit-margin-after: 0.83em;
}

div[ML_CookieMan="Container"]
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  h1 {
  font-size: 1.17em;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
}

div[ML_CookieMan="Container"]
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  h1 {
  font-size: 1em;
  -webkit-margin-before: 1.33__qem;
  -webkit-margin-after: 1.33em;
}

div[ML_CookieMan="Container"]
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  h1 {
  font-size: 0.83em;
  -webkit-margin-before: 1.67__qem;
  -webkit-margin-after: 1.67em;
}

div[ML_CookieMan="Container"]
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  :-webkit-any(article, aside, nav, section)
  h1 {
  font-size: 0.67em;
  -webkit-margin-before: 2.33__qem;
  -webkit-margin-after: 2.33em;
}

div[ML_CookieMan="Container"] h2 {
  display: block;
  font-size: 1.5em;
  -webkit-margin-before: 0.83__qem;
  -webkit-margin-after: 0.83em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

div[ML_CookieMan="Container"] h3 {
  display: block;
  font-size: 1.17em;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

div[ML_CookieMan="Container"] h4 {
  display: block;
  -webkit-margin-before: 1.33__qem;
  -webkit-margin-after: 1.33em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

div[ML_CookieMan="Container"] h5 {
  display: block;
  font-size: 0.83em;
  -webkit-margin-before: 1.67__qem;
  -webkit-margin-after: 1.67em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}

div[ML_CookieMan="Container"] h6 {
  display: block;
  font-size: 0.67em;
  -webkit-margin-before: 2.33__qem;
  -webkit-margin-after: 2.33em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  font-weight: bold;
}
/* tables */
div[ML_CookieMan="Container"] table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
}

div[ML_CookieMan="Container"] thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

div[ML_CookieMan="Container"] tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

div[ML_CookieMan="Container"] tfoot {
  display: table-footer-group;
  vertical-align: middle;
  border-color: inherit;
}
/* for tables without table section elements (can happen with XHTML or dynamically created tables) */
div[ML_CookieMan="Container"] table > tr {
  vertical-align: middle;
}

div[ML_CookieMan="Container"] col {
  display: table-column;
}

div[ML_CookieMan="Container"] colgroup {
  display: table-column-group;
}

div[ML_CookieMan="Container"] tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

div[ML_CookieMan="Container"] td,
div[ML_CookieMan="Container"] th {
  display: table-cell;
  vertical-align: inherit;
}

div[ML_CookieMan="Container"] th {
  font-weight: bold;
}

div[ML_CookieMan="Container"] caption {
  display: table-caption;
  text-align: -webkit-center;
}
/* lists */
div[ML_CookieMan="Container"] ul,
div[ML_CookieMan="Container"] menu,
div[ML_CookieMan="Container"] dir {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}

div[ML_CookieMan="Container"] ol {
  display: block;
  list-style-type: decimal;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 40px;
}

div[ML_CookieMan="Container"] li {
  display: list-item;
  text-align: -webkit-match-parent;
}

div[ML_CookieMan="Container"] ul ul,
div[ML_CookieMan="Container"] ol ul {
  list-style-type: circle;
}

div[ML_CookieMan="Container"] ol ol ul,
div[ML_CookieMan="Container"] ol ul ul,
div[ML_CookieMan="Container"] ul ol ul,
div[ML_CookieMan="Container"] ul ul ul {
  list-style-type: square;
}

div[ML_CookieMan="Container"] dd {
  display: block;
  -webkit-margin-start: 40px;
}

div[ML_CookieMan="Container"] dl {
  display: block;
  -webkit-margin-before: 1__qem;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

div[ML_CookieMan="Container"] dt {
  display: block;
}

div[ML_CookieMan="Container"] ol ul,
div[ML_CookieMan="Container"] ul ol,
div[ML_CookieMan="Container"] ul ul,
div[ML_CookieMan="Container"] ol ol {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
/* form elements */
div[ML_CookieMan="Container"] form {
  display: block;
  margin-top: 0__qem;
}

div[ML_CookieMan="Container"] label {
  cursor: default;
}

div[ML_CookieMan="Container"] legend {
  display: block;
  -webkit-padding-start: 2px;
  -webkit-padding-end: 2px;
  border: none;
}

div[ML_CookieMan="Container"] fieldset {
  display: block;
  -webkit-margin-start: 2px;
  -webkit-margin-end: 2px;
  -webkit-padding-before: 0.35em;
  -webkit-padding-start: 0.75em;
  -webkit-padding-end: 0.75em;
  -webkit-padding-after: 0.625em;
  border: 2px groove ThreeDFace;
  min-width: -webkit-min-content;
}

div[ML_CookieMan="Container"] button {
  -webkit-appearance: button;
}
/* Form controls don't go vertical. */
div[ML_CookieMan="Container"] input,
div[ML_CookieMan="Container"] textarea,
div[ML_CookieMan="Container"] keygen,
div[ML_CookieMan="Container"] select,
div[ML_CookieMan="Container"] button,
div[ML_CookieMan="Container"] meter,
div[ML_CookieMan="Container"] progress {
  -webkit-writing-mode: horizontal-tb !important;
}

div[ML_CookieMan="Container"] input,
div[ML_CookieMan="Container"] textarea,
div[ML_CookieMan="Container"] keygen,
div[ML_CookieMan="Container"] select,
div[ML_CookieMan="Container"] button {
  margin: 0__qem;
  font: -webkit-small-control;
  text-rendering: auto; /* FIXME: Remove when tabs work with optimizeLegibility. */
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: start;
}

div[ML_CookieMan="Container"] input[type="hidden" i] {
  display: none;
}

div[ML_CookieMan="Container"] input {
  -webkit-appearance: textfield;
  padding: 1px;
  background-color: white;
  border: 2px inset;
  -webkit-rtl-ordering: logical;
  -webkit-user-select: text;
  cursor: auto;
}

div[ML_CookieMan="Container"] input[type="search" i] {
  -webkit-appearance: searchfield;
  box-sizing: border-box;
}

div[ML_CookieMan="Container"] input::-webkit-textfield-decoration-container {
  display: flex;
  align-items: center;
  -webkit-user-modify: read-only !important;
  content: none !important;
}

div[ML_CookieMan="Container"]
  input[type="search" i]::-webkit-textfield-decoration-container {
  direction: ltr;
}

div[ML_CookieMan="Container"] input::-webkit-clear-button {
  -webkit-appearance: searchfield-cancel-button;
  display: inline-block;
  flex: none;
  -webkit-user-modify: read-only !important;
  -webkit-margin-start: 2px;
  opacity: 0;
  pointer-events: none;
}

div[ML_CookieMan="Container"]
  input:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-clear-button {
  opacity: 1;
  pointer-events: auto;
}

div[ML_CookieMan="Container"]
  input[type="search" i]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
  display: block;
  flex: none;
  -webkit-user-modify: read-only !important;
  -webkit-margin-start: 1px;
  opacity: 0;
  pointer-events: none;
}

div[ML_CookieMan="Container"]
  input[type="search" i]:enabled:read-write:-webkit-any(
    :focus,
    :hover
  )::-webkit-search-cancel-button {
  opacity: 1;
  pointer-events: auto;
}

div[ML_CookieMan="Container"]
  input[type="search" i]::-webkit-search-decoration {
  -webkit-appearance: searchfield-decoration;
  display: block;
  flex: none;
  -webkit-user-modify: read-only !important;
  -webkit-align-self: flex-start;
  margin: auto 0;
}

div[ML_CookieMan="Container"]
  input[type="search" i]::-webkit-search-results-decoration {
  -webkit-appearance: searchfield-results-decoration;
  display: block;
  flex: none;
  -webkit-user-modify: read-only !important;
  -webkit-align-self: flex-start;
  margin: auto 0;
}

div[ML_CookieMan="Container"] input::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  display: inline-block;
  cursor: default;
  flex: none;
  align-self: stretch;
  -webkit-user-select: none;
  -webkit-user-modify: read-only !important;
  opacity: 0;
  pointer-events: none;
}

div[ML_CookieMan="Container"]
  input:enabled:read-write:-webkit-any(
    :focus,
    :hover
  )::-webkit-inner-spin-button {
  opacity: 1;
  pointer-events: auto;
}

div[ML_CookieMan="Container"] keygen,
div[ML_CookieMan="Container"] select {
  border-radius: 5px;
}

div[ML_CookieMan="Container"] keygen::-webkit-keygen-select {
  margin: 0px;
}

div[ML_CookieMan="Container"] textarea {
  -webkit-appearance: textarea;
  background-color: white;
  border: 1px solid;
  -webkit-rtl-ordering: logical;
  -webkit-user-select: text;
  flex-direction: column;
  resize: auto;
  cursor: auto;
  padding: 2px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

div[ML_CookieMan="Container"] ::-webkit-input-placeholder {
  -webkit-text-security: none;
  color: darkGray;
  pointer-events: none !important;
}

div[ML_CookieMan="Container"] input::-webkit-input-placeholder {
  white-space: pre;
  word-wrap: normal;
  overflow: hidden;
  -webkit-user-modify: read-only !important;
}

div[ML_CookieMan="Container"] input[type="password" i] {
  -webkit-text-security: disc !important;
}

div[ML_CookieMan="Container"] input[type="hidden" i],
div[ML_CookieMan="Container"] input[type="image" i],
div[ML_CookieMan="Container"] input[type="file" i] {
  -webkit-appearance: initial;
  padding: initial;
  background-color: initial;
  border: initial;
}

div[ML_CookieMan="Container"] input[type="file" i] {
  align-items: baseline;
  color: inherit;
  text-align: start !important;
}

div[ML_CookieMan="Container"] input:-webkit-autofill,
div[ML_CookieMan="Container"] textarea:-webkit-autofill,
div[ML_CookieMan="Container"] select:-webkit-autofill {
  background-color: #faffbd !important;
  background-image: none !important;
  color: #000000 !important;
}

div[ML_CookieMan="Container"] input[type="radio" i],
div[ML_CookieMan="Container"] input[type="checkbox" i] {
  margin: 3px 0.5ex;
  padding: initial;
  background-color: initial;
  border: initial;
}

div[ML_CookieMan="Container"] input[type="button" i],
div[ML_CookieMan="Container"] input[type="submit" i],
div[ML_CookieMan="Container"] input[type="reset" i] {
  -webkit-appearance: push-button;
  -webkit-user-select: none;
  white-space: pre;
}

div[ML_CookieMan="Container"] input[type="file" i]::-webkit-file-upload-button {
  -webkit-appearance: push-button;
  -webkit-user-modify: read-only !important;
  white-space: nowrap;
  margin: 0;
  font-size: inherit;
}

div[ML_CookieMan="Container"] input[type="button" i],
div[ML_CookieMan="Container"] input[type="submit" i],
div[ML_CookieMan="Container"] input[type="reset" i],
div[ML_CookieMan="Container"] input[type="file" i]::-webkit-file-upload-button,
div[ML_CookieMan="Container"] button {
  align-items: flex-start;
  text-align: center;
  cursor: default;
  color: ButtonText;
  padding: 2px 6px 3px 6px;
  border: 2px outset ButtonFace;
  background-color: ButtonFace;
  box-sizing: border-box;
}

div[ML_CookieMan="Container"] input[type="range" i] {
  -webkit-appearance: slider-horizontal;
  padding: initial;
  border: initial;
  margin: 2px;
  color: #909090;
}

div[ML_CookieMan="Container"] input[type="range" i]::-webkit-slider-container,
div[ML_CookieMan="Container"]
  input[type="range" i]::-webkit-media-slider-container {
  flex: 1;
  min-width: 0;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
  display: flex;
}

div[ML_CookieMan="Container"]
  input[type="range" i]::-webkit-slider-runnable-track {
  flex: 1;
  min-width: 0;
  -webkit-align-self: center;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
  display: block;
}

div[ML_CookieMan="Container"] input[type="range" i]::-webkit-slider-thumb,
div[ML_CookieMan="Container"]
  input[type="range" i]::-webkit-media-slider-thumb {
  -webkit-appearance: sliderthumb-horizontal;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
  display: block;
}

div[ML_CookieMan="Container"] input[type="button" i]:disabled,
div[ML_CookieMan="Container"] input[type="submit" i]:disabled,
div[ML_CookieMan="Container"] input[type="reset" i]:disabled,
div[ML_CookieMan="Container"]
  input[type="file" i]:disabled::-webkit-file-upload-button,
div[ML_CookieMan="Container"] button:disabled,
div[ML_CookieMan="Container"] select:disabled,
div[ML_CookieMan="Container"] keygen:disabled,
div[ML_CookieMan="Container"] optgroup:disabled,
div[ML_CookieMan="Container"] option:disabled,
div[ML_CookieMan="Container"] select[disabled] > option {
  color: GrayText;
}

div[ML_CookieMan="Container"] input[type="button" i]:active,
div[ML_CookieMan="Container"] input[type="submit" i]:active,
div[ML_CookieMan="Container"] input[type="reset" i]:active,
div[ML_CookieMan="Container"]
  input[type="file" i]:active::-webkit-file-upload-button,
div[ML_CookieMan="Container"] button:active {
  border-style: inset;
}

div[ML_CookieMan="Container"] input[type="button" i]:active:disabled,
div[ML_CookieMan="Container"] input[type="submit" i]:active:disabled,
div[ML_CookieMan="Container"] input[type="reset" i]:active:disabled,
div[ML_CookieMan="Container"]
  input[type="file" i]:active:disabled::-webkit-file-upload-button,
div[ML_CookieMan="Container"] button:active:disabled {
  border-style: outset;
}

div[ML_CookieMan="Container"] option:-internal-spatial-navigation-focus {
  outline: black dashed 1px;
  outline-offset: -1px;
}

div[ML_CookieMan="Container"] datalist {
  display: none;
}

div[ML_CookieMan="Container"] area {
  display: inline;
  cursor: pointer;
}

div[ML_CookieMan="Container"] param {
  display: none;
}

div[ML_CookieMan="Container"] input[type="checkbox" i] {
  -webkit-appearance: checkbox;
  box-sizing: border-box;
}

div[ML_CookieMan="Container"] input[type="radio" i] {
  -webkit-appearance: radio;
  box-sizing: border-box;
}

div[ML_CookieMan="Container"] input[type="color" i] {
  -webkit-appearance: square-button;
  width: 44px;
  height: 23px;
  background-color: ButtonFace;
  /* Same as native_theme_base. */
  border: 1px #a9a9a9 solid;
  padding: 1px 2px;
}

div[ML_CookieMan="Container"]
  input[type="color" i]::-webkit-color-swatch-wrapper {
  display: flex;
  padding: 4px 2px;
  box-sizing: border-box;
  -webkit-user-modify: read-only !important;
  width: 100%;
  height: 100%;
}

div[ML_CookieMan="Container"] input[type="color" i]::-webkit-color-swatch {
  background-color: #000000;
  border: 1px solid #777777;
  flex: 1;
  min-width: 0;
  -webkit-user-modify: read-only !important;
}

div[ML_CookieMan="Container"] input[type="color" i][list] {
  -webkit-appearance: menulist;
  width: 88px;
  height: 23px;
}

div[ML_CookieMan="Container"]
  input[type="color" i][list]::-webkit-color-swatch-wrapper {
  padding-left: 8px;
  padding-right: 24px;
}

div[ML_CookieMan="Container"]
  input[type="color" i][list]::-webkit-color-swatch {
  border-color: #000000;
}

div[ML_CookieMan="Container"] input::-webkit-calendar-picker-indicator {
  display: inline-block;
  width: 0.66em;
  height: 0.66em;
  padding: 0.17em 0.34em;
  -webkit-user-modify: read-only !important;
  opacity: 0;
  pointer-events: none;
}

div[ML_CookieMan="Container"] input::-webkit-calendar-picker-indicator:hover {
  background-color: #eee;
}

input:enabled:read-write:-webkit-any(
    :focus,
    :hover
  )::-webkit-calendar-picker-indicator,
input::-webkit-calendar-picker-indicator:focus {
  opacity: 1;
  pointer-events: auto;
}

div[ML_CookieMan="Container"]
  input[type="date" i]:disabled::-webkit-clear-button,
div[ML_CookieMan="Container"]
  input[type="date" i]:disabled::-webkit-inner-spin-button,
div[ML_CookieMan="Container"]
  input[type="datetime-local" i]:disabled::-webkit-clear-button,
div[ML_CookieMan="Container"]
  input[type="datetime-local" i]:disabled::-webkit-inner-spin-button,
div[ML_CookieMan="Container"]
  input[type="month" i]:disabled::-webkit-clear-button,
div[ML_CookieMan="Container"]
  input[type="month" i]:disabled::-webkit-inner-spin-button,
div[ML_CookieMan="Container"]
  input[type="week" i]:disabled::-webkit-clear-button,
div[ML_CookieMan="Container"]
  input[type="week" i]:disabled::-webkit-inner-spin-button,
div[ML_CookieMan="Container"] input:disabled::-webkit-calendar-picker-indicator,
div[ML_CookieMan="Container"]
  input[type="date" i][readonly]::-webkit-clear-button,
div[ML_CookieMan="Container"]
  input[type="date" i][readonly]::-webkit-inner-spin-button,
div[ML_CookieMan="Container"]
  input[type="datetime-local" i][readonly]::-webkit-clear-button,
div[ML_CookieMan="Container"]
  input[type="datetime-local" i][readonly]::-webkit-inner-spin-button,
div[ML_CookieMan="Container"]
  input[type="month" i][readonly]::-webkit-clear-button,
div[ML_CookieMan="Container"]
  input[type="month" i][readonly]::-webkit-inner-spin-button,
div[ML_CookieMan="Container"]
  input[type="week" i][readonly]::-webkit-clear-button,
div[ML_CookieMan="Container"]
  input[type="week" i][readonly]::-webkit-inner-spin-button,
div[ML_CookieMan="Container"]
  input[readonly]::-webkit-calendar-picker-indicator {
  visibility: hidden;
}

div[ML_CookieMan="Container"] select {
  -webkit-appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  color: black;
  background-color: white;
  cursor: default;
}

div[ML_CookieMan="Container"] select:not(:-internal-list-box) {
  overflow: visible !important;
}

div[ML_CookieMan="Container"] select:-internal-list-box {
  -webkit-appearance: listbox;
  align-items: flex-start;
  border: 1px inset gray;
  border-radius: initial;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: text-bottom;
  -webkit-user-select: none;
  white-space: nowrap;
}

div[ML_CookieMan="Container"] optgroup {
  font-weight: bolder;
  display: block;
}

div[ML_CookieMan="Container"] option {
  font-weight: normal;
  display: block;
  padding: 0 2px 1px 2px;
  white-space: pre;
  min-height: 1.2em;
}

div[ML_CookieMan="Container"] select:-internal-list-box optgroup option:before {
  content: "\00a0\00a0\00a0\00a0";
}

div[ML_CookieMan="Container"] select:-internal-list-box option,
div[ML_CookieMan="Container"] select:-internal-list-box optgroup {
  line-height: initial !important;
}

div[ML_CookieMan="Container"] select:-internal-list-box:focus option:checked {
  background-color: -internal-active-list-box-selection !important;
  color: -internal-active-list-box-selection-text !important;
}

div[ML_CookieMan="Container"] select:-internal-list-box option:checked {
  background-color: -internal-inactive-list-box-selection !important;
  color: -internal-inactive-list-box-selection-text !important;
}

div[ML_CookieMan="Container"] select:-internal-list-box:disabled option:checked,
div[ML_CookieMan="Container"]
  select:-internal-list-box
  option:checked:disabled {
  color: gray !important;
}

div[ML_CookieMan="Container"] select:-internal-list-box hr {
  border-style: none;
}

div[ML_CookieMan="Container"] output {
  display: inline;
}
/* meter */
div[ML_CookieMan="Container"] meter {
  -webkit-appearance: meter;
  box-sizing: border-box;
  display: inline-block;
  height: 1em;
  width: 5em;
  vertical-align: -0.2em;
}

div[ML_CookieMan="Container"] meter::-webkit-meter-inner-element {
  -webkit-appearance: inherit;
  box-sizing: inherit;
  -webkit-user-modify: read-only !important;
  height: 100%;
  width: 100%;
}

div[ML_CookieMan="Container"] meter::-webkit-meter-bar {
  background: linear-gradient(
    to bottom,
    #ddd,
    #eee 20%,
    #ccc 45%,
    #ccc 55%,
    #ddd
  );
  height: 100%;
  width: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

div[ML_CookieMan="Container"] meter::-webkit-meter-optimum-value {
  background: linear-gradient(
    to bottom,
    #ad7,
    #cea 20%,
    #7a3 45%,
    #7a3 55%,
    #ad7
  );
  height: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

div[ML_CookieMan="Container"] meter::-webkit-meter-suboptimum-value {
  background: linear-gradient(
    to bottom,
    #fe7,
    #ffc 20%,
    #db3 45%,
    #db3 55%,
    #fe7
  );
  height: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

div[ML_CookieMan="Container"] meter::-webkit-meter-even-less-good-value {
  background: linear-gradient(
    to bottom,
    #f77,
    #fcc 20%,
    #d44 45%,
    #d44 55%,
    #f77
  );
  height: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}
/* progress */
div[ML_CookieMan="Container"] progress {
  -webkit-appearance: progress-bar;
  box-sizing: border-box;
  display: inline-block;
  height: 1em;
  width: 10em;
  vertical-align: -0.2em;
}

div[ML_CookieMan="Container"] progress::-webkit-progress-inner-element {
  -webkit-appearance: inherit;
  box-sizing: inherit;
  -webkit-user-modify: read-only;
  height: 100%;
  width: 100%;
}

div[ML_CookieMan="Container"] progress::-webkit-progress-bar {
  background-color: gray;
  height: 100%;
  width: 100%;
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}

div[ML_CookieMan="Container"] progress::-webkit-progress-value {
  background-color: green;
  height: 100%;
  width: 50%; /* should be removed later */
  -webkit-user-modify: read-only !important;
  box-sizing: border-box;
}
/* inline elements */
div[ML_CookieMan="Container"] u,
div[ML_CookieMan="Container"] ins {
  text-decoration: underline;
}

div[ML_CookieMan="Container"] strong,
div[ML_CookieMan="Container"] b {
  font-weight: bold;
}

div[ML_CookieMan="Container"] cite,
div[ML_CookieMan="Container"] em,
div[ML_CookieMan="Container"] var,
div[ML_CookieMan="Container"] address,
div[ML_CookieMan="Container"] dfn {
  font-style: italic;
}

div[ML_CookieMan="Container"] tt,
div[ML_CookieMan="Container"] code,
div[ML_CookieMan="Container"] kbd,
div[ML_CookieMan="Container"] samp {
  font-family: monospace;
}

div[ML_CookieMan="Container"] pre,
div[ML_CookieMan="Container"] xmp,
div[ML_CookieMan="Container"] plaintext,
div[ML_CookieMan="Container"] listing {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1__qem 0;
}

div[ML_CookieMan="Container"] mark {
  background-color: yellow;
  color: black;
}

div[ML_CookieMan="Container"] big {
  font-size: larger;
}

div[ML_CookieMan="Container"] small {
  font-size: smaller;
}

div[ML_CookieMan="Container"] s,
div[ML_CookieMan="Container"] strike,
div[ML_CookieMan="Container"] del {
  text-decoration: line-through;
}

div[ML_CookieMan="Container"] sub {
  vertical-align: sub;
  font-size: smaller;
}

div[ML_CookieMan="Container"] sup {
  vertical-align: super;
  font-size: smaller;
}

div[ML_CookieMan="Container"] nobr {
  white-space: nowrap;
}
/* states */
div[ML_CookieMan="Container"] :focus {
  outline: auto 5px -webkit-focus-ring-color;
}
/* Read-only text fields do not show a focus ring but do still receive focus */
div[ML_CookieMan="Container"] html:focus,
div[ML_CookieMan="Container"] body:focus,
div[ML_CookieMan="Container"] input[readonly]:focus {
  outline: none;
}

div[ML_CookieMan="Container"] embed:focus,
div[ML_CookieMan="Container"] iframe:focus,
div[ML_CookieMan="Container"] object:focus {
  outline: none;
}

div[ML_CookieMan="Container"] input:focus,
div[ML_CookieMan="Container"] textarea:focus,
div[ML_CookieMan="Container"] keygen:focus,
div[ML_CookieMan="Container"] select:focus {
  outline-offset: -2px;
}

div[ML_CookieMan="Container"] input[type="button" i]:focus,
div[ML_CookieMan="Container"] input[type="checkbox" i]:focus,
div[ML_CookieMan="Container"] input[type="file" i]:focus,
div[ML_CookieMan="Container"] input[type="hidden" i]:focus,
div[ML_CookieMan="Container"] input[type="image" i]:focus,
div[ML_CookieMan="Container"] input[type="radio" i]:focus,
div[ML_CookieMan="Container"] input[type="reset" i]:focus,
div[ML_CookieMan="Container"] input[type="search" i]:focus,
div[ML_CookieMan="Container"] input[type="submit" i]:focus,
div[ML_CookieMan="Container"]
  input[type="file" i]:focus::-webkit-file-upload-button {
  outline-offset: 0;
}

div[ML_CookieMan="Container"] a:-webkit-any-link {
  color: -webkit-link;
  text-decoration: underline;
  cursor: pointer;
}

div[ML_CookieMan="Container"] a:-webkit-any-link:active {
  color: -webkit-activelink;
}
/* HTML5 ruby elements */
div[ML_CookieMan="Container"] ruby,
div[ML_CookieMan="Container"] rt {
  text-indent: 0; /* blocks used for ruby rendering should not trigger this */
}

div[ML_CookieMan="Container"] rt {
  line-height: normal;
  -webkit-text-emphasis: none;
}

div[ML_CookieMan="Container"] ruby > rt {
  display: block;
  font-size: 50%;
  text-align: start;
}

div[ML_CookieMan="Container"] ruby > rp {
  display: none;
}
/* other elements */
div[ML_CookieMan="Container"] noframes {
  display: none;
}

div[ML_CookieMan="Container"] frameset,
div[ML_CookieMan="Container"] frame {
  display: block;
}

div[ML_CookieMan="Container"] frameset {
  border-color: inherit;
}

div[ML_CookieMan="Container"] iframe {
  border: 2px inset;
}

div[ML_CookieMan="Container"] details {
  display: block;
}

div[ML_CookieMan="Container"] summary {
  display: block;
}

div[ML_CookieMan="Container"] summary::-webkit-details-marker {
  display: inline-block;
  width: 0.66em;
  height: 0.66em;
  -webkit-margin-end: 0.4em;
}

div[ML_CookieMan="Container"] template {
  display: none;
}

div[ML_CookieMan="Container"] bdi,
div[ML_CookieMan="Container"] output {
  unicode-bidi: -webkit-isolate;
}

div[ML_CookieMan="Container"] bdo {
  unicode-bidi: bidi-override;
}

div[ML_CookieMan="Container"] textarea[dir="auto" i] {
  unicode-bidi: -webkit-plaintext;
}

div[ML_CookieMan="Container"] dialog:not([open]) {
  display: none;
}

div[ML_CookieMan="Container"] dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -webkit-fit-content;
  height: -webkit-fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
}

div[ML_CookieMan="Container"] dialog::backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

/* END user agent*/

/* BEGIN ckeditor*/

div[ML_CookieMan="Container"] .cke_editable {
  font-size: 13px;
  line-height: 1.6;
  /* Fix for missing scrollbars with RTL texts. (#10488) */
  word-wrap: break-word;
}

div[ML_CookieMan="Container"] blockquote {
  font-style: italic;
  font-family: Georgia, Times, "Times New Roman", serif;
  padding: 2px 0;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
}

div[ML_CookieMan="Container"] .cke_contents_ltr blockquote {
  padding-left: 20px;
  padding-right: 8px;
  border-left-width: 5px;
}

div[ML_CookieMan="Container"] .cke_contents_rtl blockquote {
  padding-left: 8px;
  padding-right: 20px;
  border-right-width: 5px;
}

div[ML_CookieMan="Container"] img.right {
  border: 1px solid #ccc;
  float: right;
  margin-left: 15px;
  padding: 5px;
}

div[ML_CookieMan="Container"] img.left {
  border: 1px solid #ccc;
  float: left;
  margin-right: 15px;
  padding: 5px;
}

div[ML_CookieMan="Container"] pre {
  white-space: pre-wrap; /* CSS 2.1 */
  word-wrap: break-word; /* IE7 */
  -moz-tab-size: 4;
  tab-size: 4;
}

div[ML_CookieMan="Container"] .marker {
  background-color: Yellow;
}

div[ML_CookieMan="Container"] span[lang] {
  font-style: italic;
}

div[ML_CookieMan="Container"] figure {
  text-align: center;
  outline: solid 1px #ccc;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 10px 20px;
  display: inline-block;
}

div[ML_CookieMan="Container"] figure > figcaption {
  text-align: center;
  display: block; /* For IE8 */
}

div[ML_CookieMan="Container"] a > img {
  padding: 1px;
  margin: 1px;
  border: none;
  outline: 1px solid #0782c1;
}

/* Widget Styles */
div[ML_CookieMan="Container"] .code-featured {
  border: 5px solid red;
}

div[ML_CookieMan="Container"] .math-featured {
  padding: 20px;
  box-shadow: 0 0 2px rgba(200, 0, 0, 1);
  background-color: rgba(255, 0, 0, 0.05);
  margin: 10px;
}

div[ML_CookieMan="Container"] .image-clean {
  border: 0;
  background: none;
  padding: 0;
}

div[ML_CookieMan="Container"] .image-clean > figcaption {
  font-size: 0.9em;
  text-align: right;
}

div[ML_CookieMan="Container"] .image-grayscale {
  background-color: white;
  color: #666;
}

div[ML_CookieMan="Container"] .image-grayscale img,
img.image-grayscale {
  filter: grayscale(100%);
}

div[ML_CookieMan="Container"] .embed-240p {
  max-width: 426px;
  max-height: 240px;
  margin: 0 auto;
}

div[ML_CookieMan="Container"] .embed-360p {
  max-width: 640px;
  max-height: 360px;
  margin: 0 auto;
}

div[ML_CookieMan="Container"] .embed-480p {
  max-width: 854px;
  max-height: 480px;
  margin: 0 auto;
}

.embed-720p {
  max-width: 1280px;
  max-height: 720px;
  margin: 0 auto;
}

div[ML_CookieMan="Container"] .embed-1080p {
  max-width: 1920px;
  max-height: 1080px;
  margin: 0 auto;
}

/* END ckeditor*/

td[ML_CookieMan="TdBannerLogo"] {
  width: 50px !important;
  padding-right: 10px !important;
}

div[ML_CookieMan="Container"] table {
  width: 100% !important;
}

div[ML_CookieMan="Container"] td {
  list-style-type: none !important;
}

div[ML_CookieMan="Container"]
  span:not([ML_Pagecontrol_Type="Banner_Icon_Text"]) {
  font-family: "Segoe UI", Poppins, Arial !important;
  color: black;
  font-size: 12px;
}
div[ML_CookieMan="BannerIcon"] img,
div[ML_CookieMan="TdBannerLogo"] img {
  background-color: #fff;
  border-radius: 100%;
}

/*Modal*/

div[ML_CookieMan="Modal"] {
  display: none !important;
  position: fixed !important;
  z-index: 2147483646 !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgb(0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0.55) !important;
  font-size: 14px !important;
  color: black !important;
  line-height: 12px !important;
  font-weight: normal !important;
}

div[ML_CookieMan="Modal"] img {
  max-width: none !important;
}

div[ML_CookieMan="ModalHeader"] {
}

div[ML_CookieMan="ModalHeader"] [ML_CookieMan="ModalHeader_Container"] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 800px) {
  div[ML_CookieMan="ModalHeader"] [ML_CookieMan="ModalHeader_Container"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

div[ML_CookieMan="ModalHeader"]
  [ML_CookieMan="ModalHeader_Container"]
  [ML_CookieMan="ModalHeader_LeftContainer"] {
  display: flex;
  width: 205px;
}

div[ML_CookieMan="ModalHeader"]
  [ML_CookieMan="ModalHeader_Container"]
  [ML_CookieMan="ModalHeader_CenterContainer"] {
}

div[ML_CookieMan="ModalHeader"]
  [ML_CookieMan="ModalHeader_Container"]
  [ML_CookieMan="ModalHeader_RightContainer"] {
  display: flex;
  justify-content: space-between;
  width: 185px;
}

/* BOX */

div[ML_CookieMan^="ModalContent"] {
  background-color: #fefefe !important;
  margin: auto !important;
  padding: 10px 20px !important;
  border: 1px solid #888 !important;
  position: absolute !important;
  left: -50% !important;
  top: 50% !important;
  width: 40% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 10px 10px 10px 10px !important;
  -webkit-transition: left 0.5s ease-out !important; /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: left 0.5s ease-out !important; /* Firefox 4-15 */
  -o-transition: left 0.5s ease-out !important; /* Opera 10.50–12.00 */
  transition: left 0.5s ease-out !important;
  height: fit-content !important;
}

div[ML_CookieMan^="ModalFooter"] {
  bottom: 0px !important;
  width: 100% !important;
  padding: 5px !important;
  display: inline-table !important;
  display: flex !important;
  gap: 10px !important;
  overflow: auto !important;
}

div[ML_CookieMan^="ModalBody"] {
  overflow-y: auto !important;
  padding: 5px !important;
  margin-top: 10px !important;
  border-top: 0px solid #eeeeee !important;
  height: unset !important;
}
@media (max-width: 748px) {
  div[ML_CookieMan^="ModalBody"] {
    max-height: unset !important;
    overflow: scroll;
    height: 300px !important;
    display: block !important;
  }
}

div[ML_CookieMan="Modal"] span[ML_CookieMan="Header"] {
  font-size: 17px !important;
}

div[ML_CookieMan="Modal"] span[ML_CookieMan="PolicyDate"] {
  font-size: 10px !important;
}

div[ML_CookieMan="Modal"] span[ML_CookieMan="Title"] {
  font-size: 15px !important;
}

div[ML_CookieMan="Modal"] span[ML_CookieMan="Description"] {
  font-size: 11px !important;
  line-height: 1.4 !important;
  color: #696969 !important;
}

div[ML_CookieMan="Modal"] span[ML_CookieMan="Description"] a {
  cursor: pointer !important;
}

div[ML_CookieMan="Modal"] a[ML_CookieMan="Description"] {
  font-size: 11px !important;
  line-height: 1.4 !important;
  color: #696969 !important;
  cursor: pointer !important;
}

div[ML_CookieMan="Modal"] [ML_CookieMan="Answer"] {
  padding-left: 40px;
}

div[ML_CookieMan="Modal"] tr[ML_CookieMan="TrHeader"] td {
  border-bottom: 0.5px solid lightgrey !important;
}

div[ML_CookieMan="Modal"] tr[ML_CookieMan="CategoryRow"] td {
  border-bottom: 0.5px solid lightgrey !important;
}

div[ML_CookieMan="CookiesList"] {
  margin: auto !important;
  margin-top: 10px !important;
}

div[ML_CookieMan="Container"] [ML_PageControl_Key="CookieCategoryIcon"] {
  font-size: 12px !important;
  margin-top: 11px !important;
  color: black;
}

/* elenco categorie */

div[ML_CookieMan="Categories"] {
  text-align: justify !important;
  margin-right: 5px !important;
}

div[ML_CookieMan="Categories"] table[ML_CookieMan="Categories_TableContainer"] {
  text-align: justify !important;
  margin-right: 5px !important;
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
}
div[ML_CookieMan="Categories"]
  table[ML_CookieMan="Categories_TableContainer"]
  td[ML_PageControl_Key="Category"] {
  border-radius: 5px !important;
  padding-inline: 10px !important;
}

div[ML_CookieMan="Categories"]
  table[ML_CookieMan="Categories_TableContainer"]
  td[ML_PageControl_Key="ExtraCategory"] {
  border-radius: 5px !important;
  width: unset;
  display: flex;
}
div[ML_CookieMan="Categories"]
  table[ML_CookieMan="Categories_TableContainer"]
  td[ML_PageControl_Key="ExtraCategory"] {
  border: 0px !important;
  padding-left: 0px !important;
}
div[ML_CookieMan="Categories"]
  table[ML_CookieMan="Categories_TableContainer"]
  td[ML_PageControl_Key="Category"] {
  border: 1px solid #000;
  display: contents;
}
@media (min-width: 765px) {
  table[ML_CookieMan="Categories_TableContainer"] tbody {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}

div[ML_CookieMan="Categories"] table[ML_CookieMan="Categories_TableInfo"] {
  border-collapse: collapse !important;
}

table[ML_CookieMan="Categories_TableInfo"] tbody {
  display: block !important;
  border: 1px solid #000;
  border-radius: 5px !important;
}

div[ML_CookieMan="Categories"] table[ML_CookieMan="Categories_TableInfo"] td {
  border: none !important;
  padding-left: 5px;
  padding-right: 5px;
}

div[ML_CookieMan="Categories"]
  table[ML_CookieMan="Categories_TableInfo"]
  td:first-of-type {
  /*display: list-item !important;*/
  width: 16px !important;
  text-align: center;
  display: none !important;
}
/* elenco categorie END*/

/* elenco soggetti */

div[ML_CookieMan="GenericUsers_Container"] {
  display: table !important;
  width: 100% !important;
}

div[ML_PageControl_Key="PnlGenericUsersIcon"] {
  width: 4% !important;
  display: table-cell !important;
  vertical-align: middle !important;
}

div[ML_PageControl_Key="PnlGenericUsers"] {
  width: 96% !important;
  display: table-cell !important;
}

div[ML_CookieMan="GenericUsers"] {
  text-align: justify !important;
  margin-right: 5px !important;
}

div[ML_CookieMan="GenericUsers"] span {
  font-size: 11px !important;
  line-height: 1.4 !important;
  color: #696969 !important;
}

div[ML_CookieMan="GenericUsers"] {
  margin: auto !important;
}

div[ML_CookieMan="GenericUsers"] table tr {
  cursor: pointer !important;
}

div[ML_CookieMan="GenericUsers"] table tr:hover {
  background-color: #1d2b2f14 !important;
}

div[ML_CookieMan="GenericUsers_Container"]
  [ML_PageControl_Key="CookieCategoryIcon"] {
  margin-top: 0px !important;
  border-radius: 50% !important;
  border: 1px solid grey !important;
  line-height: 2em !important;
  width: 2em !important;
  text-align: center !important;
  transition: 0.5s !important;
  cursor: pointer !important;
}

div[ML_CookieMan="GenericUsers"]
  table[ML_CookieMan="GenericUsers_TableContainer"] {
  text-align: justify !important;
  margin-right: 5px !important;
  border-collapse: separate !important;
  border-spacing: 0 3px !important;
}

div[ML_CookieMan="GenericUsers"]
  table[ML_CookieMan="GenericUsers_TableContainer"]
  tr:hover {
  background-color: #1d2b2f14 !important;
}

div[ML_CookieMan="GenericUsers"]
  table[ML_CookieMan="GenericUsers_TableContainer"]
  td {
  border-radius: 5px !important;
  padding-left: 3px !important;
}

div[ML_CookieMan="GenericUsers"]
  table[ML_CookieMan="GenericUsers_TableInfo"]
  td {
  border: none !important;
}

div[ML_CookieMan="CategoryDetail"] div[ML_CookieMan="GenericUser_Container"] {
  border: 1px #c0c0c0 solid !important;
  border-radius: 5px !important;
  float: left !important;
  padding: 8px !important;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
  min-height: 185px !important;
}

div[ML_CookieMan="CategoryDetail"]
  div[ML_CookieMan="GenericUser_Container"]
  tr {
  height: 20px !important;
}

div[ML_CookieMan="CategoryDetail"] div[ML_CookieMan="GenericUser_Container"] i {
  font-size: 12px !important;
  color: #696969 !important;
}

div[ML_CookieMan="CategoryDetail"]
  div[ML_CookieMan="GenericUser_Container"]
  tr[ML_PageControl_Type="GenericUser_TrLink"] {
  cursor: pointer !important;
}

div[ML_CookieMan="CategoryDetail"]
  div[ML_CookieMan="GenericUser_Container"]
  tr[ML_PageControl_Type="GenericUser_TrLink"]
  span {
  color: #4e9de6 !important;
}

div[ML_CookieMan="CategoryDetail"]
  div[ML_CookieMan="GenericUser_Container"]
  tr[ML_PageControl_Type="GenericUser_TrLink"]
  i {
  color: #4e9de6 !important;
}

div[ML_CookieMan="CategoryDetail"]
  div[ML_PageControl_Key="GenericUsersSubtitle"] {
  margin-bottom: 20px !important;
  margin-top: 5px !important;
}

div[ML_CookieMan="CategoryDetail"]
  div[ML_PageControl_Key="FurtherInformationSubtitle"] {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

/* elenco soggetti END*/

/*sezione del TCF*/
div[ML_CookieMan="CategoryDetail"]
  div[ML_PageControl_Key="TCF_Topics_Subtitle"] {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

/* dettaglio Topic TCF */
div[ML_CookieMan="TCF_Topic_Detail"] {
  display: none !important;
}

div[ML_CookieMan="TCF_Topic_Detail"]
  table[ML_CookieMan="TCF_Topic_TableContainer"] {
  text-align: justify !important;
  margin-right: 5px !important;
  border-collapse: separate !important;
}

div[ML_CookieMan="TCF_Topic_Detail"]
  table[ML_CookieMan="TCF_Topic_TableContainer"]
  > tbody
  > tr
  > td {
  border: 1px #bf0426 solid !important;
  border-radius: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div[ML_CookieMan="TCF_Topic_Detail"]
  table[ML_CookieMan="TCF_Topic_TableContainer"]
  td {
  border: none !important;
}

div[ML_CookieMan="OptTargetsList"] {
}

div[ML_CookieMan="OptTargetsList"] [ML_CookieMan="OptTargets_TableContainer"] {
  padding: 0px 0px !important;
}

div[ML_CookieMan="OptTargetsList"] [ML_CookieMan="OptTargets_TableInfo"] {
  border: 1px solid #dadada !important;
  border-collapse: collapse !important;
  border-radius: 5px !important;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargets_TableInfo"]
  td:first-of-type {
  padding: 3px !important;
  width: 16%;
  text-align: left !important;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargets_TableInfo"]
  tr:has(+ tr[ML_CookieMan_FirstLocked="true"]) {
  height: 30px;
  position: relative;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargets_TableInfo"]
  tr:has(+ tr[ML_CookieMan_FirstLocked="true"]):before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #dadada;
  height: 30px;
  width: 100%;
  transform: translateY(-50%);
}

div[ML_CookieMan="OptTargetsList"] [ML_CookieMan="TrHeader_OptTarget"] {
  background: #1d2b2f14 !important;
  cursor: pointer !important;
  height: 23px !important;
}

div[ML_CookieMan="OptTargetsList"] [ML_CookieMan="OptTargetDetail_TableInfo"] {
  border-collapse: collapse !important;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  td {
  padding: 3px !important;
  vertical-align: top !important;
  text-align: justify !important;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  span {
  font-size: 11px !important;
  line-height: 1.4 !important;
  color: #696969 !important;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  [ML_PageControl_Key="VendorPolicyList_External"] {
  border: 1px solid gray;
  border-radius: 2px;
  /*			width: fit-content;*/
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  [ML_PageControl_Key="VendorPolicyList_External"]
  [ML_PageControl_Key="VendorPolicyList_Header"] {
  padding: 3px 9px;
  background-color: #1d2b2f14 !important;
  cursor: pointer;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  [ML_PageControl_Key="VendorPolicyList_External"]
  [ML_PageControl_Key="VendorPolicyList_Header"]
  span {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  [ML_PageControl_Key="VendorPolicyList_External"]
  [ML_PageControl_Key="VendorPolicyList_Header"]
  span:hover {
  text-decoration-style: solid;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  [ML_PageControl_Key="VendorPolicyList_External"]
  [ML_PageControl_Key="VendorPolicyListContainer"] {
  max-height: 300px;
  overflow-y: auto;
  padding: 4px;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  [ML_PageControl_Key="VendorPolicyList_External"]
  [ML_PageControl_Key="VendorPolicyListContainer"]
  ul
  > li {
  padding: 2px;
}

div[ML_CookieMan="OptTargetsList"]
  [ML_CookieMan="OptTargetDetail_TableInfo"]
  [ML_PageControl_Key="VendorPolicyList_External"]
  [ML_PageControl_Key="VendorPolicyListContainer"]
  ul
  > li
  > a {
  font-size: 11px !important;
  line-height: 1.4 !important;
  color: #696969 !important;
}

[ML_CookieMan="VendorCategories_Container"] {
  display: flex !important;
}

[ML_CookieMan="VendorCategories_Container"]
  [ML_CookieMan="VendorCategory_Container"] {
  width: 200px;
  padding: 5px;
  border: 1px #bf0426 solid;
  border-radius: 5px;
  margin: 7px 2px 3px;
  cursor: pointer;
}

[ML_CookieMan="VendorCategories_Container"]
  [ML_CookieMan="VendorCategory_Container"]
  span {
  color: #bf0426;
}

[ML_CookieMan="VendorCategories_Container"]
  [ML_CookieMan="VendorCategory_Container"][ML_PageControl_Value="1"] {
  background-color: #bf0426;
}

[ML_CookieMan="VendorCategories_Container"]
  [ML_CookieMan="VendorCategory_Container"][ML_PageControl_Value="1"]
  span {
  font-weight: bold;
  color: white !important;
}

div [ML_CookieMan="OptVendorsList"] {
}

div [ML_CookieMan="OptVendorsList"] [ML_CookieMan="OptVendorsList_Choise"] {
  display: flex;
  padding: 5px 2px;
  gap: 5px;
}

div
  [ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorsList_Choise"]
  [ML_CookieMan="OptVendorsList_Button"] {
  background-color: #9cd7f46e !important;
  border: 1px solid #bf0426 !important;
  padding: 5px;
  height: auto !important;
  width: auto !important;
}

div
  [ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorsList_Choise"]
  [ML_CookieMan="OptVendorsList_Button"]
  span {
  color: #bf0426;
}

div
  [ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorsList_Choise"]
  [ML_CookieMan="OptVendorsList_Button"]:hover {
  background-color: #bf0426 !important;
}

div
  [ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorsList_Choise"]
  [ML_CookieMan="OptVendorsList_Button"]:hover
  span {
  color: white !important;
}

div
  [ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorsList_Choise"]
  [ML_CookieMan="OptVendorsList_Button"][ML_CookieMan_Status="Selected"] {
  background-color: #bf0426 !important;
}

div
  [ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorsList_Choise"]
  [ML_CookieMan="OptVendorsList_Button"][ML_CookieMan_Status="Selected"]
  span {
  color: white !important;
}

div [ML_CookieMan="OptVendorsList"] [ML_CookieMan="OptVendors_TableContainer"] {
  padding: 0px 0px !important;
}

div[ML_CookieMan="OptVendorsList"] [ML_CookieMan="OptVendor_TableInfo"] {
  border: 1px solid #dadada !important;
  border-collapse: collapse !important;
  border-radius: 5px !important;
}

div[ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendor_TableInfo"]
  td:first-of-type {
  padding: 3px !important;
  text-align: left !important;
}

div[ML_CookieMan="OptVendorsList"] [ML_CookieMan="TrHeader_OptVendor"] {
  background: #1d2b2f14 !important;
  height: 23px !important;
}

div[ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="TrHeader_OptVendor"][onclick] {
  cursor: pointer !important;
}

div[ML_CookieMan="OptVendorsList"] [ML_CookieMan="TrSwitch_OptVendor"] {
  height: 25px;
}

div[ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="TrSwitch_OptVendor"]
  [ML_PageControl_Type="SelectionSwitch"] {
  cursor: pointer !important;
}

div[ML_CookieMan="OptVendorsList"] [ML_CookieMan="OptVendorDetail_TableInfo"] {
  border-collapse: collapse !important;
}

div[ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorDetail_TableInfo"]
  td {
  padding: 3px !important;
  vertical-align: top !important;
  text-align: justify !important;
}

div[ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorDetail_TableInfo"]
  span {
  font-size: 11px !important;
  line-height: 1.4 !important;
  color: #696969 !important;
}

div[ML_CookieMan="OptVendorsList"]
  [ML_CookieMan="OptVendorDetail_TableInfo"]
  a {
  font-size: 11px !important;
  line-height: 1.4 !important;
  color: #696969 !important;
}

/* dettaglio Topic TCF END*/

/* dettaglio approfondimenti */
div[ML_CookieMan="FurtherInformationDetail"] {
  display: none !important;
}

div[ML_CookieMan="FurtherInformationDetail"]
  table[ML_CookieMan="FurtherInformation_TableContainer"] {
  text-align: justify !important;
  margin-right: 5px !important;
  border-collapse: separate !important;
}

div[ML_CookieMan="FurtherInformationDetail"]
  table[ML_CookieMan="FurtherInformation_TableContainer"]
  > tbody
  > tr
  > td {
  border: 1px #bf0426 solid !important;
  border-radius: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div[ML_CookieMan="FurtherInformationDetail"]
  table[ML_CookieMan="FurtherInformation_TableInfo"]
  td {
  border: none !important;
}

/* dettaglio approfondimenti */

/* dettaglio categorie cookie*/
div[ML_CookieMan="CategoryDetail"] {
  display: none !important;
}

div[ML_CookieMan="CategoryDetail"]
  table[ML_CookieMan="Category_TableContainer"] {
  text-align: justify !important;
  margin-right: 5px !important;
  border-collapse: separate !important;
}

div[ML_CookieMan="CategoryDetail"]
  table[ML_CookieMan="Category_TableContainer"]
  > tbody
  > tr
  > td {
  border: 1px #bf0426 solid !important;
  border-radius: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

div[ML_CookieMan="CategoryDetail"] table[ML_CookieMan="Category_TableInfo"] td {
  border: none !important;
}

div[ML_CookieMan="CategoryDetail"]
  table[ML_CookieMan="Category_TableInfo"]
  td:first-of-type {
  display: list-item !important;
  width: 16px !important;
}

div[ML_CookieMan="CategoryDetail"] [ML_PageControl_SubKey="ShowMore"] {
  cursor: pointer !important;
  width: 16px !important;
  padding-right: 7px !important;
}

div[ML_CookieMan="CategoryDetail"] [ML_PageControl_SubKey="HideMore"] {
  display: none !important;
  cursor: pointer !important;
  width: 16px !important;
  padding-right: 7px !important;
}
/* dettaglio categorie cookie -- end*/

/* gruppo cookie*/
tr[ML_CookieMan="TrHeader_Group"] {
}
/* gruppo cookie END*/

/* dettaglio cookie */
div[ML_CookieMan="CookiesList"] {
}

div[ML_CookieMan="CookiesList"] [ML_CookieMan="Cookies_TableContainer"] {
  padding: 0px 0px !important;
}

div[ML_CookieMan="CookiesList"] [ML_CookieMan="Cookie_TableInfo"] {
  border: 1px solid #dadada !important;
  border-collapse: collapse !important;
  border-radius: 5px !important;
}

div[ML_CookieMan="CookiesList"]
  [ML_CookieMan="Cookie_TableInfo"]
  td:first-of-type {
  padding: 3px !important;
  width: 16%;
  text-align: left !important;
}

div[ML_CookieMan="CookiesList"] [ML_CookieMan="TrHeader_Cookie"] {
  background: #1d2b2f14 !important;
  cursor: pointer !important;
  height: 23px !important;
}

div[ML_CookieMan="CookiesList"] [ML_CookieMan="CookieDetail_TableInfo"] {
  border-collapse: collapse !important;
}

div[ML_CookieMan="CookiesList"] [ML_CookieMan="CookieDetail_TableInfo"] td {
  padding: 3px !important;
  vertical-align: top !important;
  text-align: justify !important;
}

div[ML_CookieMan="CookiesList"] [ML_CookieMan="CookieDetail_TableInfo"] span {
  font-size: 11px !important;
  line-height: 1.4 !important;
  color: #696969 !important;
}

div[ML_CookieMan="CookiesList"]
  [ML_CookieMan="Cookie_TableInfo"]
  [ML_PageControl_Key="CookieCategoryIcon"] {
  margin-top: 0px !important;
}
/* dettaglio cookie END */

/* Switch a 4 stati */
[ML_PageControl_Type="SelectionSwitch"] {
  background-color: #bf0426 !important;
  width: 60px !important;
  height: 22px !important;
  position: relative !important;
  display: inline-block !important;
  border-radius: 10px !important;
  transition: 0.4s !important;
}

[ML_PageControl_Type="SelectionSwitch"]
  [ML_PageControl_Key="SwitchState"][ML_PageControl_SubKey="One"] {
  width: 12px !important;
  height: 12px !important;
  position: relative !important;
  display: inline-block !important;
  border-radius: 6px !important;
  margin-top: 5px !important;
  transition: 0.4s !important;
  background-color: white !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="1"] {
  background-color: #545252 !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="1"]
  [ML_PageControl_Key="SwitchState"][ML_PageControl_SubKey="One"] {
  margin-left: 5px !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="2"] {
  background-color: #5e7d8c !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="2"]
  [ML_PageControl_Key="SwitchState"][ML_PageControl_SubKey="One"] {
  margin-left: 23px !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="3"] {
  background-color: #bf0426 !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="3"]
  [ML_PageControl_Key="SwitchState"][ML_PageControl_SubKey="One"] {
  margin-left: 44px !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="4"] {
  cursor: not-allowed !important;
  background-color: #c0c0c0 !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="4"]
  [ML_PageControl_Key="SwitchState"][ML_PageControl_SubKey="One"] {
  display: none !important;
}

[ML_PageControl_Type="SelectionSwitch"][ML_PageControl_Value="4"]
  [ML_PageControl_Key="SwitchState"][ML_PageControl_SubKey="Four"] {
  display: inline-block !important;
}

[ML_PageControl_Key="SwitchState"][ML_PageControl_SubKey="Four"] {
  display: none !important;
  margin-top: 5px !important;
  margin-left: 47px !important;
  font-size: 12px !important;
  color: #bf0426 !important;
}
/* Switch a 4 stati END */

div[ML_CookieMan="CategoriesList"] {
  margin: auto !important;
  /*	margin-top: 10px !important;*/
}

div[ML_CookieMan="CategoriesList"] span {
  font-size: 12px !important;
}

div[ML_CookieMan="CategoriesList"] span[ML_CookieMan="SubInfo"] {
  font-size: 11px !important;
  color: #696969 !important;
}

div[ML_CookieMan="CategoriesList"] table td {
  height: 25px !important;
}

div[ML_CookieMan="CategoriesList"] table tr {
  cursor: pointer !important;
  float: none;
}

div[ML_CookieMan="CategoriesList"]
  table
  tr:not([ML_PageControl_Pragma="NoHover"]):hover {
  background-color: #1d2b2f14 !important;
}

tr[ML_CookieMan="TrHeader"] td {
  padding: 2px !important;
}

.TrAccordionActive {
  transition: all 2s linear !important;
}

.TrAccordion {
  display: none !important;
}

tr[ML_PageControl_Key="Full"] {
  display: none !important;
}

/* Checkbox*/
[ML_CookieMan="CheckboxSwitch"] {
  position: relative !important;
  display: inline-block !important;
  width: 60px !important;
  height: 30px !important;
}

span[ML_CookieMan="CheckboxSlider"] {
  position: absolute !important;
  cursor: pointer !important;
  top: 3px !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #545252 !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
  width: 60px !important;
  height: 22px !important;
  border-radius: 34px !important;
}

input:checked + [ML_CookieMan="CheckboxSlider"] {
  background-color: #bf0426 !important;
}

input:checked + [ML_CookieMan="CheckboxSlider"]:before {
  -webkit-transform: translateX(26px) !important;
  -ms-transform: translateX(26px) !important;
  transform: translateX(26px) !important;
}

span[ML_CookieMan="CheckboxSlider"]:before {
  height: 11px !important;
  width: 11px !important;
  left: 17px !important;
  bottom: 5px !important;
}

span[ML_CookieMan="CheckboxSlider"]:before {
  border-radius: 50% !important;
}

span[ML_CookieMan="CheckboxSlider"]:before {
  position: absolute !important;
  content: "" !important;
  background-color: white !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
}

/*Banner Social Icons*/
div[ML_CookieMan="BannerSocialIcons"] {
  max-height: 35px !important;
  background-color: white !important;
  position: absolute !important;
  right: -2px !important;
  top: -42px !important;
  padding: 3px !important;
  z-index: 2147483646 !important;
  border-top: 1px solid grey !important;
  border-left: 1px solid grey !important;
  -webkit-box-shadow: -4px -3px 2px 1px rgba(50, 50, 50, 0.75) !important;
  -moz-box-shadow: -4px -3px 2px 1px rgba(50, 50, 50, 0.75) !important;
  box-shadow: -4px -3px 2px 1px rgba(50, 50, 50, 0.75) !important;
  -webkit-transition: all 0.3s ease-out !important;
  -moz-transition: all 0.3s ease-out !important;
  -o-transition: all 0.3s ease-out !important;
  transition: all 0.3s ease-out !important;
}

/*Banner Social Icons*/
div[ML_CookieMan="ModalSocialIcons"] {
  max-height: 35px !important;
  background-color: white !important;
  position: absolute !important;
  border-radius: 5px 5px 0px 0px !important;
  padding: 3px !important;
  z-index: 2147483645 !important;
  border-top: 1px solid grey !important;
  -webkit-transition: all 0.3s ease-out !important;
  -moz-transition: all 0.3s ease-out !important;
  -o-transition: all 0.3s ease-out !important;
  transition: all 0.3s ease-out !important;
  right: 0px !important;
  top: -40px !important;
}

div[ML_CookieMan="Banner"] img {
  max-width: 40px !important;
}

div[ML_CookieMan="Banner"] div[ML_CookieMan*="Button"] {
  padding: 0px !important;
}

.ML_Cookieman_Shortcut_lt {
  top: 50px !important;
  left: 50px !important;
}

.ML_Cookieman_Shortcut_rt {
  top: 50px !important;
  right: 50px !important;
}

.ML_Cookieman_Shortcut_lb {
  bottom: 50px !important;
  left: 50px !important;
}

.ML_Cookieman_Shortcut_rb {
  bottom: 50px !important;
  right: 50px !important;
}

/* Grid System */
div[ML_CookieMan="Column-Fill"] {
  flex-basis: 0 !important;
  flex-grow: 1 !important;
  max-width: 100% !important;
}

div[ML_CookieMan="Column-1"] {
  width: 8.33333333333333% !important;
  min-width: 100px !important;
}

div[ML_CookieMan="Column-2"] {
  width: 16.66666666666667% !important;
}

div[ML_CookieMan="Column-3"] {
  width: 25% !important;
}

div[ML_CookieMan="Column-4"] {
  width: 33.33333333% !important;
}

div[ML_CookieMan="Column-5"] {
  width: 41.66666666666667% !important;
}

div[ML_CookieMan="Column-6"] {
  width: 50% !important;
}

div[ML_CookieMan="Column-7"] {
  width: 58.33333333% !important;
}

div[ML_CookieMan="Column-8"] {
  width: 66.66666667% !important;
}

div[ML_CookieMan="Column-9"] {
  width: 75% !important;
}

div[ML_CookieMan="Column-10"] {
  width: 83.33333333333333% !important;
}

div[ML_CookieMan="Column-11"] {
  width: 91.66666667% !important;
}

div[ML_CookieMan="Column-12"] {
  width: 100% !important;
}

div[ML_CookieMan*="Column"] {
  float: left !important;
}

div[ML_CookieMan="Row"] {
  display: flex !important;
}

div[ML_CookieMan="ExternalRow"] {
  margin-right: 10px !important;
  margin-left: 10px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  display: flex !important;
}

@media (max-width: 800px) {
  div[ML_CookieMan="Banner"] div[ML_CookieMan="Column-3"] {
    width: 50% !important;
  }
}

/* Media queries*/

@media only screen and (max-width: 800px) {
  div[ML_CookieMan*="Column"] {
    width: 100% !important;
    padding-bottom: 4px !important;
    flex-basis: initial !important;
  }

  div[ML_CookieMan="ExternalRow"] {
    display: block !important;
  }

  div[ML_CookieMan="Row"] {
    display: block !important;
  }

  div[ML_CookieMan="ModalFooter"] {
    display: block !important;
  }

  img[ML_CookieMan="IabImage_Header"] {
    display: none !important;
  }

  img[ML_CookieMan="GoogleImage_Header"] {
    display: none !important;
  }

  img[ML_CookieMan="IabImage_Footer"] {
    display: inline !important;
  }

  img[ML_CookieMan="GoogleImage_Footer"] {
    display: inline !important;
  }

  [ML_CookieMan_Form_Key="tdSocials"] img:nth-of-type(n + 5) {
    display: none !important;
  }

  div[ML_CookieMan="ModalHeader"]
    [ML_CookieMan="ModalHeader_Container"]
    [ML_CookieMan="ModalHeader_LeftContainer"] {
    width: 30px !important;
  }
  div[ML_CookieMan="ModalHeader"]
    [ML_CookieMan="ModalHeader_Container"]
    [ML_CookieMan="ModalHeader_LeftContainer"]
    img {
    width: 60px !important;
  }

  div[ML_CookieMan="ModalHeader"]
    [ML_CookieMan="ModalHeader_Container"]
    [ML_CookieMan="ModalHeader_CenterContainer"] {
    text-align: left;
    width: calc(100% - 265px);
  }
}

@media only screen and (min-width: 801px) {
  div[ML_PageControl_Type="pnlCommandContainer"] {
    position: absolute !important;
    top: 50% !important;
    right: 0% !important;
    transform: translate(0%, -50%) !important;
    width: 33% !important;
    min-width: 380px !important;
  }

  div[ML_PageControl_Type="pnlCommandExternalContainer"] {
    min-width: 380px !important;
  }
}

@media only screen and (max-width: 800px) {
  div [ML_CookieMan="ModalContent"] {
    width: 80% !important;
    height: 90% !important;
    overflow-y: auto !important;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 801px) {
  div[ML_CookieMan="ModalContent"] {
    width: 70% !important;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1101px) {
  div[ML_CookieMan="ModalContent"] {
    width: 65% !important;
  }
}

/*Generic Button*/
div[ML_CookieMan^="ModalFooter"][ML_CookieMan*="Button"] {
  padding: 5px 5px !important;
}
div[ML_CookieMan*="Button"] {
  width: 100% !important;
  cursor: pointer !important;
  text-align: center !important;
  border-radius: 10px !important;
  display: table !important;
  transition: all 0.3s ease !important;
  padding: 5px 5px !important;
}

div[ML_CookieMan*="Button"] span {
  font-size: 12px !important;
  font-weight: normal !important;
  cursor: pointer !important;
  text-transform: uppercase !important;
  padding: 0px !important;
  display: inline-block !important;
  vertical-align: middle !important;
}

/* Manage Button */
#ctl00_Banner_ShowPolicyButton {
  background-color: #494373 !important;
}
div[ML_CookieMan="ManageButton"] {
  background-color: #494373 !important;
  border: 1px solid #d2d2d2 !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
}

div[ML_CookieMan="ManageButton"] td {
  background-color: unset !important;
}

div[ML_CookieMan="ManageButton"] span {
  color: #fff !important;
}

div[ML_CookieMan="ManageButton"] > span {
  margin-top: 4px !important;
}

div[ML_CookieMan="ManageButton"] i {
  color: #cccccc !important;
  /**padding-left: 3px !important;*/
}

div[ML_CookieMan="ManageButton"]:hover {
  background-color: #494373 !important;
}

div[ML_CookieMan="ManageButton"]:hover span {
  color: #ffffff !important;
}

div[ML_CookieMan="ManageButton"]:hover i {
  color: #ffffff !important;
}

/* Back Button */
div[ML_CookieMan="BackButton"] {
  background-color: white !important;
  border: 1px solid #545252 !important;
  box-sizing: border-box !important;
}

div[ML_CookieMan="BackButton"] td {
  background-color: unset !important;
}

div[ML_CookieMan="BackButton"] span {
  color: #545252 !important;
}

div[ML_CookieMan="BackButton"] i {
  color: #545252 !important;
}

div[ML_CookieMan="BackButton"]:hover {
  background-color: #a9a5a5 !important;
}

div[ML_CookieMan="BackButton"]:hover span {
  color: #ffffff !important;
}

div[ML_CookieMan="BackButton"]:hover i {
  color: #ffffff !important;
}

/* Accept Button */
[ML_CookieMan_form="Form"] div[ML_CookieMan="AcceptButton"] :not(i) {
  all: unset;
}

div[ML_CookieMan="AcceptButton"] {
  background-color: #bf0426 !important;
  border: 1px solid #d2d2d2 !important;
  box-sizing: border-box !important;
}

div[ML_CookieMan="AcceptButton"] span {
  color: white !important;
}

div[ML_CookieMan="AcceptButton"] table {
  background-color: transparent !important;
}

div[ML_CookieMan="AcceptButton"] tr {
  background-color: transparent !important;
}

div[ML_CookieMan="AcceptButton"] td {
  background-color: unset !important;
}

div[ML_CookieMan="AcceptButton"] i {
  color: white !important;
  margin-top: 3px !important;
  /*padding-left: 3px !important;*/
}

div[ML_CookieMan="AcceptButton"]:hover {
  background-color: #bf0426 !important;
}

/*		div[ML_CookieMan=AcceptButton]:hover span {
			color: #FFFFFF !important;
		}

		div[ML_CookieMan=AcceptButton]:hover i {
			color: #FFFFFF !important;
		}*/

/* Save Button */
div[ML_CookieMan="SaveButton"] {
  border: 1px solid #545252 !important;
  box-sizing: border-box !important;
}

div[ML_CookieMan="SaveButton"] td {
  background-color: unset !important;
}

div[ML_CookieMan="SaveButton"] span {
  color: #2d2d2d !important;
  /*		font-weight: 800;*/
}

div[ML_CookieMan="SaveButton"] i {
  color: #bf0426 !important;
}

div[ML_CookieMan="SaveButton"]:hover {
  background-color: #bf0426 !important;
}

div[ML_CookieMan="SaveButton"]:hover span {
  color: #ffffff !important;
}

div[ML_CookieMan="SaveButton"]:hover i {
  color: #ffffff !important;
}

/*Deny Button*/
div[ML_CookieMan="DenyButton"] {
  border: 1px solid #545252 !important;
  box-sizing: border-box !important;
}

div[ML_CookieMan="DenyButton"] td {
  background-color: unset !important;
}

div[ML_CookieMan="DenyButton"] span {
  color: #2d2d2d !important;
}

div[ML_CookieMan="DenyButton"] i {
  color: #2d2d2d !important;
}

div[ML_CookieMan="DenyButton"]:hover {
  background-color: #2f2e2e !important;
}

div[ML_CookieMan="DenyButton"]:hover span {
  color: white !important;
}

div[ML_CookieMan="DenyButton"]:hover i {
  color: white !important;
}

i[ML_CookieMan="Icon"] {
  /*display: inline-block !important;*/
  vertical-align: middle !important;
  font-size: 20px !important;
  display: none;
}

div[ML_CookieMan_Form="Form"] input[ML_PageControl_Type="TextBox"] {
  border: 1px solid #646464 !important;
  background-color: white !important;
  height: 30px !important;
  width: 100% !important;
  border-radius: 5px !important;
  vertical-align: middle !important;
  font-size: 14px !important;
  color: black !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-bottom: 5px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

div[ML_CookieMan_Form="Form"] textarea[ML_PageControl_Type="TextArea"] {
  border: 1px solid #646464 !important;
  background-color: white !important;
  width: 100% !important;
  border-radius: 5px !important;
  vertical-align: middle !important;
  font-size: 14px !important;
  color: black !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-bottom: 5px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Poppins, Arial !important;
  overflow: auto !important;
}

div[ML_CookieMan_Form="Form"] ::-webkit-input-placeholder {
  font-style: italic !important;
}

div[ML_CookieMan_Form="Form"] ::placeholder {
  font-style: italic !important;
}

div[ML_CookieMan_Form="Form"] :-ms-input-placeholder {
  font-style: italic !important;
}

div[ML_CookieMan_Form="Form"] ::-ms-input-placeholder {
  font-style: italic !important;
}

div[ML_CookieMan_Form="Form"] span[ML_PageControl_Type="TextLabel"] {
  width: 100% !important;
  display: inline-block !important;
  margin-bottom: 5px !important;
}

div[ML_CookieMan_Form="Form"] span[ML_CookieMan_Form="lblError"] {
  width: 100% !important;
  display: inline-block !important;
  margin-bottom: 5px !important;
}

div[ML_CookieMan="Banner"] tbody {
  background-color: transparent !important;
}
@media (max-width: 800px) {
  div[ML_CookieMan="Banner"] div[ML_CookieMan="Column-3"] {
    width: 50% !important;
  }
}

div[ML_CookieMan="Modal"] tbody {
  background-color: transparent !important;
}

div[ML_CookieMan_Form="Container"] table {
  background-color: transparent !important;
}

div[ML_CookieMan_Form="Container"] tr:not(.TRSelected) {
  background-color: transparent !important;
}

div[ML_CookieMan_Form="Container"] tbody {
  background-color: transparent !important;
}

/*
div[ML_CookieMan=Modal] {
	font-size: 14px;
	color: black;
	line-height: 12px;
	font-weight: normal;
}
	*/

/*Banner*/
div[ML_CookieMan="Banner"] {
  font-size: 14px !important;
  color: black !important;
  line-height: normal !important;
  font-weight: normal !important;
  min-height: 77px !important;
  left: 0px !important;
  background-color: white !important;
  width: 100% !important;
  position: fixed !important;
  bottom: -100% !important;
  padding: 0px !important;
  z-index: 2147483645 !important;
  border-top: 1px solid grey !important;
  -webkit-box-shadow: unset !important;
  -moz-box-shadow: unset !important;
  box-shadow: unset !important;
  -webkit-transition: all 0.3s ease-out !important; /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all 0.3s ease-out !important; /* Firefox 4-15 */
  -o-transition: all 0.3s ease-out !important; /* Opera 10.50–12.00 */
  transition: all 0.3s ease-out !important;
}

span[ML_CookieMan="LblLink"] {
  text-decoration: none !important;
  color: #4e9de6 !important;
  cursor: pointer !important;
  word-break: break-word;
  word-wrap: break-word;
}

div[ML_CookieMan_Form="Container"] {
  position: relative !important;
}

div[ML_CookieMan_Form="Form"] table {
  width: 100% !important;
}

div[ML_CookieMan_Form="Form"] td[ML_CookieMan_Form_Key="Col_A"] {
  padding: 3px 10px !important;
  width: 14% !important;
  vertical-align: top !important;
}

div[ML_CookieMan_Form="Form"] td[ML_CookieMan_Form_Key="Col_A"] span {
  white-space: nowrap !important;
}

div[ML_CookieMan_Form="Form"] td[ML_CookieMan_Form_Key="Col_B"] {
  padding: 3px 10px !important;
  /*display : flex !important;*/
}

div[ML_CookieMan_Form="Form"] div[ML_CookieMan_Form_Key="Panel_B"] {
  display: flex !important;
}

tr[ml_cookieman_form_type="trType_CheckBox_A"]
  div[ML_CookieMan_Form_Key="Panel_B"] {
  display: block !important;
}

div[ML_CookieMan_Form="Form"]
  td[ML_CookieMan_Form_Key="Col_B"]
  [ML_CookieMan_Form_Key="FieldIcon"] {
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-top: 2px !important;
  width: 25px !important;
  font-size: 18px !important;
  display: none !important;
}

div[ML_CookieMan_Form="Form"] tr[ML_CookieMan_Form_Key="trFieldsContainer"] {
  display: table-row !important;
}

@media only screen and (max-width: 800px) {
  div[ML_CookieMan_Form="Form"]
    tr[ML_CookieMan_Form_Key="trFieldsContainer"]
    td:not(:first-child) {
    display: block !important;
  }
}

div[ML_CookieMan_Form="Form"]
  tr[ML_CookieMan_Form_Key="trMainCommandContainer"] {
  display: table-row !important;
}

div[ML_CookieMan_Form="Form"]
  tr[ML_CookieMan_Form_Key="trExtendedTextContainer"] {
  display: none !important;
}

div[ML_CookieMan_Form="Form"]
  tr[ML_CookieMan_Form_Key="trSecondCommandContainer"] {
  display: none !important;
}

div[ML_CookieMan_Form="Form"]
  tr[ML_CookieMan_Form_Type="trType_Upload_A"]
  td[ML_CookieMan_Form_Key="Col_B"]
  div[ML_CookieMan_Form_Key="Panel_B"] {
  flex-wrap: wrap !important;
}

div[ML_CookieMan_Form="Form"]
  tr[ML_CookieMan_Form_Type="trType_Upload_A"]
  td[ML_CookieMan_Form_Key="Col_B"]
  div[ML_CookieMan_Form_Key="Panel_B"]
  input[type="file"] {
  width: 100% !important;
}

div[ML_CookieMan_Form_Key="LoaderContainer"] {
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 90 !important;
  display: none !important;
  opacity: 0.8 !important;
  background-color: #2a3e4c !important;
  overflow: hidden !important;
  text-align: center !important;
  vertical-align: middle !important;
}

div[ML_CookieMan_Form_Key="Loader"] {
  border-radius: 25px !important;
  border-top: 16px solid #6a7071 !important;
  border-right: 16px solid #689bb4 !important;
  border-bottom: 16px solid #2e3537 !important;
  border-left: 16px solid #bfbfbf !important;
  width: 60px !important;
  height: 60px !important;
  -webkit-animation: spin 2s linear infinite !important;
  animation: spin 2s linear infinite !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  display: none !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

div[ML_CookieMan_Form_Key="ResponseContainer"] {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  display: none !important;
  background-color: white !important;
  opacity: initial !important;
  width: 50% !important;
  height: 50% !important;
  z-index: 100 !important;
}

div[ML_CookieMan_Form_Key="ResponseContainer"] [ML_CookieMan="ManageButton"] {
  bottom: 0px !important;
  /*position: absolute !important;*/
  z-index: 120 !important;
}

div[ML_CookieMan_Form="Container"]
  [ML_CookieMan_Form_Key="CheckBox"]
  input[type="checkbox"] {
  margin: 0px 5px 0px 0px !important;
  clip: unset !important;
  position: unset !important;
  padding: unset !important;
}

div[ML_CookieMan_Form="Container"] [ML_CookieMan_Form_Key="CheckBox"] label {
  display: contents !important;
}

div[ML_CookieMan_Form="Container"]
  [ML_CookieMan_Form_Key="CheckBox"]
  label::before {
  content: unset !important;
}

div[ML_CookieMan_Form="Container"] table {
  border: none !important;
  margin: 0px !important;
}

div[ML_CookieMan_Form="Container"] td {
  border: none !important;
  padding: 0px !important;
  background-color: unset !important;
  color: unset !important;
}

div[ML_CookieMan_Form="Container"]
  [ML_CookieMan_Form_Key="trMainCommandContainer"]
  > td {
  padding: 10px 0px 0px 0px !important;
}

div[ML_CookieMan_Form="Container"]
  [ML_CookieMan_Form_Key="trMainCommandContainer"]
  div[ML_CookieMan*="Button"]
  span {
  /*/padding: 0px 10px 0px 0px !important;*/
}

div[ML_CookieMan_Form="Container"]
  [ML_CookieMan_Form_Key="trMainCommandContainer"]
  td
  > table
  > tbody
  > tr
  > td:first-of-type {
  padding: 0px 10px !important;
}

div[ML_CookieMan_Form="Container"]
  [ML_CookieMan_Form_Key="trMainCommandContainer"]
  td
  > table
  > tbody
  > tr
  > td:last-of-type {
  padding: 0px 10px !important;
}

/*tema DARK*/

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form="FormTitle"] {
  color: white !important;
  font-size: 18px !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"] span {
  /*color: #777777 !important;*/
  color: white !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="ResponseTextOK"] {
  color: black !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="ResponseTextKO"] {
  color: black !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextBox"] {
  border: 1px solid grey !important;
  background-color: #222222 !important;
  height: 30px !important;
  width: 100% !important;
  color: grey !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextBox"]::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: grey !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextBox"]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: grey !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextBox"]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: grey !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextBox"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextArea"]::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: grey !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextArea"]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: grey !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextArea"]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: grey !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextArea"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="TextArea"] {
  /*border: 1px solid grey !important;*/
  background-color: #222222 !important;
  width: 100% !important;
  color: grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  margin-bottom: 5px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Poppins, Arial !important;
  overflow: auto !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Key="Combo"] {
  /*border: 1px solid grey !important;*/
  height: 30px !important;
  background-color: #222222 !important;
  width: 100% !important;
  color: grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  margin-bottom: 5px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Poppins, Arial !important;
  overflow: auto !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  [ML_CookieMan_Form_Type="ErrorLabel"] {
  color: white !important;
  font-size: 14px !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  div[ML_CookieMan="ManageButton"]
  span {
  color: white !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  div[ML_CookieMan="AcceptButton"]
  span {
  color: white !important;
}

/*tema WHITE*/

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="WHITE"]
  [ML_CookieMan_Form="FormTitle"] {
  color: black !important;
  font-size: 18px !important;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="WHITE"]
  [ML_CookieMan_Form_Key="TextBox"] {
  border: 1px solid grey !important;
  background-color: white !important;
  height: 30px !important;
  width: 100% !important;
  color: grey !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="WHITE"]
  [ML_CookieMan_Form_Key="TextArea"] {
  /*border: 1px solid grey !important;*/
  background-color: white !important;
  width: 100% !important;
  color: grey !important;
  /*font-size: 14px !important;*/
  font-weight: normal !important;
  padding-left: 10px !important;
  margin-bottom: 5px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  /*font-family: Poppins,Arial !important;*/
  overflow: auto !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="WHITE"]
  [ML_CookieMan_Form_Key="Combo"] {
  /*border: 1px solid grey !important;*/
  height: 30px !important;
  background-color: white !important;
  width: 100% !important;
  color: grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  margin-bottom: 5px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Poppins, Arial !important;
  overflow: auto !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="WHITE"]
  [ML_CookieMan_Form_Type="ErrorLabel"] {
  color: red !important;
  font-size: 14px !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  input[type="text"][ML_CookieMan_Pragma*="MANDATORY"] {
  border-top: 1px solid #689bb3 !important;
  border-right: 5px solid #689bb3 !important;
  border-bottom: 1px solid #689bb3 !important;
  border-left: 5px solid #689bb3 !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  textarea[ML_CookieMan_Pragma*="MANDATORY"] {
  border-top: 1px solid #689bb3 !important;
  border-right: 5px solid #689bb3 !important;
  border-bottom: 1px solid #689bb3 !important;
  border-left: 5px solid #689bb3 !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  select[ML_CookieMan_Pragma*="MANDATORY"] {
  border-top: 1px solid #689bb3 !important;
  border-right: 5px solid #689bb3 !important;
  border-bottom: 1px solid #689bb3 !important;
  border-left: 5px solid #689bb3 !important;
}

/* CommandAction */

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan="CommandAction"] {
  height: 30px !important;
  width: 100% !important;
  cursor: pointer !important;
  text-align: center !important;
  border-radius: 2px !important;
  transition: all 0.3s ease !important;
  background-color: #545252 !important;
  border: 1px solid #d2d2d2 !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  min-width: 97% !important;
  max-width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan="CommandAction"]
  table {
  height: 30px !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan="CommandAction"]
  td {
  background-color: unset !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan="CommandAction"]
  span {
  font-size: 12px !important;
  font-weight: normal !important;
  cursor: pointer !important;
  /*text-transform: uppercase !important;*/
  padding: 0px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  color: #cccccc !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan="CommandAction"]
  i {
  color: #cccccc !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan="CommandAction"]:hover {
  background-color: #494373 !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan="CommandAction"]:hover
  span {
  color: #ffffff !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan="CommandAction"]:hover
  i {
  color: #ffffff !important;
}

/*tema NO_STYLE*/

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="NO_STYLE"]
  [ML_CookieMan_Form="FormTitle"] {
  font-size: 18px !important;
  color: black;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="NO_STYLE"]
  [ML_CookieMan_Form_Key="TextBox"] {
  border: 1px solid grey !important;
  background-color: transparent !important;
  height: 30px !important;
  width: 100% !important;
  color: grey !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="NO_STYLE"]
  [ML_CookieMan_Form_Key="TextArea"] {
  border: 1px solid grey !important;
  background-color: transparent !important;
  width: 100% !important;
  color: grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  margin-bottom: 5px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Poppins, Arial !important;
  overflow: auto !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="NO_STYLE"]
  [ML_CookieMan_Form_Key="Combo"] {
  height: 30px !important;
  background-color: transparent !important;
  width: 100% !important;
  color: grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  margin-bottom: 5px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Poppins, Arial !important;
  overflow: auto !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="NO_STYLE"]
  [ML_CookieMan_Form_Type="ErrorLabel"] {
  color: red !important;
  font-size: 14px !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="NO_STYLE"]
  [ML_CookieMan_Form_Key="ComboFields"] {
  height: 30px !important;
  background-color: transparent !important;
  width: 100% !important;
  color: grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Poppins, Arial !important;
  overflow: auto !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="NO_STYLE"]
  [ML_CookieMan_Form_Key="TextBoxFields"] {
  height: 30px !important;
  background-color: transparent !important;
  border: 1px solid grey !important;
  width: 100% !important;
  color: grey !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding-left: 10px !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-family: Poppins, Arial !important;
  overflow: auto !important;
}

/* Modal Form*/

div[ML_CookieMan_Form="Modal"] {
  display: none;
  position: fixed !important;
  z-index: 2147483645 !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgb(0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0.55) !important;
}

div[ML_CookieMan_Form="Modal"] img {
  max-width: none !important;
}

div[ML_CookieMan_Form="ModalContent"] {
  background-color: #fefefe;
  margin: auto;
  padding: 10px 20px;
  border: 1px solid #888 !important;
  position: absolute !important;
  width: 50%;
  height: fit-content;
  border-radius: 5px 5px 5px 5px !important;
  -webkit-box-shadow: 0 0 3px rgba(50, 50, 50, 0.75) !important;
  -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0.75) !important;
  box-shadow: 0 0 3px rgba(50, 50, 50, 0.75) !important;
}

.notransition {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.transition {
  -webkit-transition: all 0.5s ease-out !important; /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: all 0.5s ease-out !important; /* Firefox 4-15 */
  -o-transition: all 0.5s ease-out !important; /* Opera 10.50–12.00 */
  transition: all 0.5s ease-out !important;
  left: -50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.transitionEnded {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

@media only screen and (max-width: 800px) {
  div[ML_CookieMan_Form="ModalContent"] {
    width: 98% !important;
  }
}

div[ML_CookieMan_Form="ModalFooter"] {
  bottom: 0px !important;
  width: 98% !important;
  padding: 5px !important;
  display: inline-table !important;
  display: flex !important;
}

div[ML_CookieMan_Form="ModalBody"] {
  overflow-y: auto !important;
  margin-top: 10px !important;
  max-height: fit-content !important;
}

div[ML_CookieMan_Form="Modal"] span[ML_CookieMan_Form="Header"] {
  font-size: 17px !important;
}

div[ML_CookieMan_Form="Modal"] span[ML_CookieMan_Form="Title"] {
  font-size: 15px !important;
}

div[ML_CookieMan_Form="Modal"] tr[ML_CookieMan_Form="TrHeader"] td {
  border-bottom: 0.5px solid lightgrey !important;
}

div[ML_CookieMan_Form="Modal"] td[ML_CookieMan_Form_Key="Col_A"] {
  padding: 3px 0px 0px 0px !important;
  width: 14% !important;
  vertical-align: top !important;
}

div[ML_CookieMan_Form="Modal"] td[ML_CookieMan_Form_Key="Col_A"] span {
  white-space: nowrap !important;
}

div[ML_CookieMan_Form="Modal"] td[ML_CookieMan_Form_Key="Col_B"] {
  padding: 3px 3px 0px 0px !important;
}

div[ML_CookieMan_Form="Modal"]
  td[ML_CookieMan_Form_Key="Col_B"]
  [ML_CookieMan_Form_Key="FieldIcon"] {
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-top: 2px !important;
  width: 25px !important;
  font-size: 18px !important;
  display: none !important;
}

img[ML_CookieMan_Form_Key="SocialFilename"]:hover {
  opacity: 0.9 !important;
}

img[ML_CookieMan_Form_Key="SocialFilename"] {
  margin-right: 5px !important;
  cursor: pointer !important;
}

img[ML_CookieMan_Form_Key="SocialFilename"]:last-of-type {
  margin-right: 9px !important;
}

td[ML_CookieMan_Form_Key="tdPoweredBy"] img {
  margin-top: 8px !important;
  margin-left: 2px !important;
}

div[ML_CookieMan="ModalFooter"]
  img:not(
    [ML_CookieMan="IabImage_Footer"],
    [ML_CookieMan="GoogleImage_Footer"]
  ) {
  display: inline !important;
}

td[ML_CookieMan_Form_Key="tdSocials"] {
  display: flex !important;
  float: right !important;
  margin-top: 10px !important;
  text-align: right !important;
  padding-left: 7px !important;
}

div[ML_CookieMan="Modal"] span[ml_pagecontrol_entityfield="CookieName"] {
  word-break: break-word !important;
}

tr[ML_CookieMan_Form_Key="trHeaderContainer"] th {
  border: none !important;
  background-color: unset !important;
}

/* STAR RATINGS */
[ML_CookieMan_Form="Container"] .rate {
  display: inline-block !important;
  border: 0 !important;
}
/* Hide radio */
[ML_CookieMan_Form="Container"] .rate > input {
  display: none !important;
}
/* Order correctly by floating highest to the right */
[ML_CookieMan_Form="Container"] .rate > label {
  float: right !important;
  color: #cccccc !important;
}
/* The star of the show */
[ML_CookieMan_Form="Container"] .rate > label:before {
  display: inline-block !important;
  font-size: 35px !important;
  /*padding: .3rem .2rem !important;
			margin: 0 !important;*/
  cursor: pointer !important;
  font-family: "FontAwesome Free" !important;
  content: "\2605" !important; /* full star */
  margin-top: -8px !important;
}
/* Zero stars rating */
[ML_CookieMan_Form="Container"] .rate > label:last-child:before {
  content: "\2605" !important; /* empty star outline */
}

[ML_CookieMan_Form="Container"] .rate > input:checked ~ label,
[ML_CookieMan_Form="Container"] .rate > label:hover,
[ML_CookieMan_Form="Container"] .rate > label:hover ~ label {
  color: #ffd024 !important;
}
/* color previous stars on hover */

/* Hover highlights */
[ML_CookieMan_Form=Container] .rate > input:checked + label:hover,
			[ML_CookieMan_Form=Container] .rate > input:checked ~ label:hover, /* highlight current and previous stars */
			[ML_CookieMan_Form=Container] .rate > input:checked ~ label:hover ~ label, /* highlight previous selected stars for new rating */
			[ML_CookieMan_Form=Container] .rate > label:hover ~ input:checked ~ label /* highlight previous selected stars */ {
  color: #fde640 !important;
}

/* SaveForm Container */
div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SaveFormContainer"] {
  width: 100% !important;
  display: inline !important;
}

/* Reset Button */
div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"] {
  background-color: #545252 !important;
  border: 1px solid #d2d2d2 !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  width: 48% !important;
  float: left !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"] {
  background-color: #545252 !important;
  border: 1px solid #d2d2d2 !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  width: 50% !important;
  float: left !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]
  td,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]
  td {
  background-color: unset !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]
  span,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]
  span {
  color: #cccccc !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]
  i,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]
  i {
  color: #cccccc !important;
  position: relative !important;
  left: 3px !important;
  font-size: 15px !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]:hover,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]:hover {
  background-color: #494373 !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]:hover
  span,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]:hover
  span {
  color: #ffffff !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]:hover
  i,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="ResetButton"]:hover
  i {
  color: #ffffff !important;
}

/* Close Button */

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="CloseButton"] {
  background-color: #545252 !important;
  border: 1px solid #d2d2d2 !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  width: 100% !important;
  float: left !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="CloseButton"]
  td {
  background-color: unset !important;
}

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="CloseButton"]
  span {
  color: #cccccc !important;
}

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="CloseButton"]
  i {
  color: #cccccc !important;
  position: relative !important;
  left: 3px !important;
  font-size: 15px !important;
}

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="CloseButton"]:hover {
  background-color: #494373 !important;
}

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="CloseButton"]:hover
  span {
  color: #ffffff !important;
}

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="CloseButton"]:hover
  i {
  color: #ffffff !important;
}

/* Submit Button */

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"] {
  background-color: #bf0426 !important;
  border: 1px solid #d2d2d2 !important;
  box-sizing: border-box !important;
  width: 48% !important;
  float: right !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"] {
  background-color: #bf0426 !important;
  border: 1px solid #d2d2d2 !important;
  box-sizing: border-box !important;
  width: 50% !important;
  float: right !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  span,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  span {
  color: #cccccc !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  table,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  table {
  background-color: transparent !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  tr,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  tr {
  background-color: transparent !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  td,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  td {
  background-color: unset !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  i,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]
  i {
  color: #cccccc !important;
  position: relative !important;
  font-size: 15px !important;
  left: 3px !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]:hover,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]:hover {
  background-color: #bf0426 !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]:hover
  span,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]:hover
  span {
  color: #ffffff !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]:hover
  i,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form="SubmitButton"]:hover
  i {
  color: #ffffff !important;
}

/*Generic Form Button*/
div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form*="Button"],
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form*="Button"] {
  height: 30px !important;
  cursor: pointer !important;
  text-align: center !important;
  border-radius: 2px !important;
  transition: all 0.3s ease !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form*="Button"]
  table,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form*="Button"]
  table {
  height: 30px !important;
}

div[ML_CookieMan_Form="Container"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form*="Button"]
  span,
div[ML_CookieMan_Form="ModalFooter"]:not([ML_CookieMan_Theme="INHERIT"])
  div[ML_CookieMan_Form*="Button"]
  span {
  font-size: 12px !important;
  font-weight: normal !important;
  cursor: pointer !important;
  /*text-transform: uppercase !important;*/
  padding: 0px !important;
  display: inline-block !important;
  vertical-align: middle !important;
}

tr[ML_CookieMan_Form_Type="trType_SaveForm"]:not([ML_CookieMan_Theme="INHERIT"])
  td {
  padding: 3px 10px !important;
}

/**/
div[ML_CookieMan_Form="Container"]
  div[ML_CookieMan_Form_Key="pnlExtendedTextContainer"][ML_CookieMan_Form_Pragma="PopUp"] {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 80% !important;
  width: 80% !important;
  z-index: 2147483647 !important;
  padding: 10px !important;
  display: none;
}

div[ML_CookieMan_Form="Container"]
  div[ML_CookieMan_Form_Key="pnlExtendedTextContainer"][ML_CookieMan_Form_Pragma="PopUp"] {
  background-color: #fefefe !important;
  margin: auto !important;
  border: 1px solid #888 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 80% !important;
  width: 80% !important;
  border-radius: 5px 5px 5px 5px !important;
  -webkit-box-shadow: 0 0 3px rgb(50 50 50 / 75%) !important;
  -moz-box-shadow: 0 0 3px rgba(50, 50, 50, 0.75) !important;
  box-shadow: 0 0 3px rgb(50 50 50 / 75%) !important;
  z-index: 2147483647 !important;
}

div[ML_CookieMan_Form="Container"]
  div[ML_CookieMan_Form_Key="pnlExtendedTextContainer"][ML_CookieMan_Form_Pragma="PopUp"]
  div {
  height: calc(100% - 25px);
  overflow-y: auto;
}

div
  [ML_CookieMan_Form="Container"][ML_CookieMan_Theme="DARK"]
  div[ML_CookieMan_Form_Key="pnlExtendedTextContainer"][ML_CookieMan_Form_Pragma="PopUp"] {
  background-color: black !important;
}

div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="WHITE"]
  div[ML_CookieMan_Form_Key="pnlExtendedTextContainer"][ML_CookieMan_Form_Pragma="PopUp"],
div[ML_CookieMan_Form="Container"][ML_CookieMan_Theme="NO_STYLE"]
  div[ML_CookieMan_Form_Key="pnlExtendedTextContainer"][ML_CookieMan_Form_Pragma="PopUp"] {
  background-color: white !important;
}

div [ML_CookieMan_Form_Key="pnlExtendedTextLoaderContainer"] {
  position: absolute !important;
  z-index: 2147483645 !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgb(0, 0, 0) !important;
  background-color: rgba(0, 0, 0, 0.55) !important;
}

/* Classi basi stile form */
.cookieman-form-input-text {
  width: 100%;
  height: 20px;
}

.cookieman-form-input-textarea {
  width: 100%;
}

.cookieman-form-button {
  cursor: pointer;
  border: 1px solid black;
  text-align: center;
  float: left;
  width: 150px;
}

.cookieman-form-label-error {
  color: red;
}

@keyframes gradient_tex {
  0% {
    background-position-x: 0px;
  }

  100% {
    background-position-x: 800px;
  }
}
