.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 70vh;
  padding: 50px 0;
}

.login .content {
  border: 1px solid #eee;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  width: 100%;
  max-width: 450px;
}

.login h1 {
  margin: 0;
  text-align: center;
  font-size: 26px;
}
.login p {
  margin-bottom: 0;
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: var(--smoot);
}

.login form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
