.maintenance {
	position: absolute;
	height: 100dvh;
	width: 100%;
	top: 0;
	left: 0;
	padding: 30px;
	background-color: var(--c3);
	color: var(--c3c);
	display: flex;
	align-items: center;
	justify-content: center;
}

.maintenance .content {
	width: 100%;
	max-width: 500px;
}

.maintenance .logo {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
}

.maintenance .logo img {
	max-width: 250px;
}

.maintenance .text {
	text-align: center;
}
