.checkout {
	max-width: 1100px;
	margin: 0 auto;
	padding: 15px;
}

.checkout .columns {
	display: grid;
	gap: 15px;
	align-items: start;
}

@media (min-width: 900px) {
	.checkout .columns {
		grid-template-columns: auto 450px;
	}
}

.checkout .content {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.checkout h1 {
	margin: 0;
	text-align: left;
	font-size: 20px;
}

.checkout h1 .subtitle {
	font-size: 16px;
	opacity: 0.5;
}

.checkout .box {
	display: flex;
	flex-direction: column;
	gap: 15px;
	border: 1px solid #eee;
	border-radius: 10px;
	padding: 15px;
}

.checkout .box.has-error {
	border-color: red;
}

.checkout .error-label {
	color: red;
}

.checkout .line {
	display: grid;
	grid-template-columns: auto 100px 110px;
}

.checkout .line > *:nth-child(2) {
	justify-self: center;
}

.checkout .line > *:nth-child(3) {
	justify-self: end;
}

.checkout .line.subtotal {
	font-weight: 600;
}

.checkout .line.total {
	font-weight: 600;
	font-size: 20px;
}

.checkout #customer-form button {
	display: none;
}

.checkout .delete-row-button {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	background-color: var(--c1);
	color: var(--c1c);
}

.payment-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100dvh;
	background-color: #00000080;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	z-index: 400;
}

.payment-popup .content {
	background-color: #fff;
	border-radius: 10px;
	width: 100%;
	max-width: 500px;
	max-height: 100%;
	padding: 15px;
}

.payment-popup .content .nexi-iframe {
	border: 0;
	width: 100%;
	min-height: calc(100dvh - 100px);
}
